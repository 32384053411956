/* custom */
$main: #ff6303;
$secondary: #545454;
$dark: #333333;
$light:#ffffff;
$lightgrey: #f6f6f6;
$font-icon: " Font Awesome 5 Free ";
$main_border:1px solid #f0f0f0;
@import "animate";
@import "fontawesome-all";
@import "Linearicons-Free";
@import "ionicons";

@mixin pos-transition($number) {
	-webkit-transition: all $number linear;
	-moz-transition: all $number linear;
	-ms-transition: all $number linear;
	-o-transition: all $number linear;
	transition: all $number linear;
}

@mixin pos-transform($number) {
	-moz-transform: scale3d($number, $number, $number);
	-webkit-transform: scale3d($number, $number, $number);
	-o-transform: scale3d($number, $number, $number);
	-ms-transform: scale3d($number, $number, $number);
	transform: scale3d($number, $number, $number);
}

@function get1024-vw($target) {
	// 1 vw is equal to 1% of the viewport width
	$vw-context: (1024 * .01) * 1px; // 1% viewport width
	@return ($target/$vw-context) * 1vw;
}

@function get767-vw($target) {
	// 1 vw is equal to 1% of the viewport width 
	$vw-context: (767 * .01) * 1px; // 1% viewport width
	@return ($target/$vw-context) * 1vw;
}

@function get480-vw($target) {
	// 1 vw is equal to 1% of the viewport width
	$vw-context: (480 * .01) * 1px; // 1% viewport width
	@return ($target/$vw-context) * 1vw;
}

@mixin placeholder {
	&::-moz-placeholder {
		@content
	}

	&::-webkit-input-placeholder {
		@content
	}

	&:-ms-input-placeholder {
		@content
	}
}

@mixin placeholder-style {
	color: $light;
}

@mixin screenS {
	@media screen and (max-width: 767px) {
		@content
	}
}

@mixin screenM {
	@media screen and (max-width: 991px) {
		@content
	}
}

@mixin screenL {
	@media screen and (min-width: 992px) {
		@content
	}
}

@mixin screenXL {
	@media screen and (min-width: 1500px) {
		@content
	}
}

@mixin screenXXL {
	@media screen and (min-width: 1800px) {
		@content
	}
}

@mixin pageTitle {
	font-size: 50px;
	text-transform: uppercase;
	font-weight: 700;
	border-bottom: 5px solid #f2f2f2;
	padding-bottom: .5em;
	margin-bottom: .35em;

	@include screenM {
		font-size: 20px;
		margin-bottom: 1.5em;
	}
}

.animatetab .owl-carousel .owl-item {
	-moz-animation: zoomIn 500ms ease;
	-o-animation: zoomIn 500ms ease;
	-webkit-animation: zoomIn 500ms ease;
	animation: zoomIn 500ms ease;
}

/* fonts */

/* end fonts */
/* css jquery */
/* Slider */
.slick-loading .slick-list {
	background: #fff url('./ajax-loader.gif') center center no-repeat;
}

/* Icons */
@font-face {
	font-family: 'slick';
	font-weight: normal;
	font-style: normal;
	src: url('./fonts/slick.eot');
	src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg');
}

/* Arrows */
.slick-prev,
.slick-next {
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 50%;
	display: block;
	width: 20px;
	height: 20px;
	padding: 0;
	z-index: 1;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	cursor: pointer;
	color: transparent;
	border: none;
	outline: none;
	background: transparent;
}

.slick-vertical {

	.slick-prev,
	.slick-next {
		left: 50%;
		-webkit-transform: translate(-50%, 0);
		-ms-transform: translate(-50%, 0);
		transform: translate(-50%, 0);
	}

	.slick-prev {
		top: -25px;
	}

	.slick-next {
		bottom: -25px;
		top: auto;
	}

	.slick-prev:before {
		content: "\ea35";
	}

	[dir='rtl'] .slick-prev:before {
		content: "\ea32";
	}

	.slick-next:before {
		content: "\ea32";
	}

	[dir='rtl'] .slick-next:before {
		content: "\ea35";
	}
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
	color: transparent;
	outline: none;
	background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
	opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
	opacity: .25;
}

.slick-prev:before,
.slick-next:before {
	font-family: 'Linearicons-Free';
	font-size: 20px;
	line-height: 1;
	opacity: .75;
	color: white;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-prev {
	left: -25px;

	@media (max-width: 991px) {
		left: -15px;
	}
}

[dir='rtl'] .slick-prev {
	right: -25px;
	left: auto;

	@media (max-width: 991px) {
		right: -15px;
	}
}

.slick-prev:before {
	content: "\e875";
}

[dir='rtl'] .slick-prev:before {
	content: '→';
	content: "\e876";

}

.slick-next {
	right: -25px;

	@media (max-width: 991px) {
		right: -15px;
	}
}

[dir='rtl'] .slick-next {
	right: auto;
	left: -25px;

	@media (max-width: 991px) {
		left: -15px;
	}
}

.slick-next:before {
	content: '→';
	content: "\e876";
}

[dir='rtl'] .slick-next:before {
	content: "\e875";
}

/* Dots */
.slick-dotted.slick-slider {
	margin-bottom: 30px;
}

.slick-dots {
	position: absolute;
	bottom: -25px;
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
}

.slick-dots li {
	position: relative;
	display: inline-block;
	width: 20px;
	height: 20px;
	margin: 0 5px;
	padding: 0;
	cursor: pointer;
}

.slick-dots li button {
	font-size: 0;
	line-height: 0;
	display: block;
	width: 20px;
	height: 20px;
	padding: 5px;
	cursor: pointer;
	color: transparent;
	border: 0;
	outline: none;
	background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
	outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
	opacity: 1;
}

.slick-dots li button:before {
	font-family: 'slick';
	font-size: 6px;
	line-height: 20px;
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	content: '•';
	text-align: center;
	opacity: .25;
	color: black;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
	opacity: .75;
	color: black;
}

/* Slider */
.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0;
}

.slick-list:focus {
	outline: none;
}

.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.slick-track:before,
.slick-track:after {
	display: table;
	content: '';
}

.slick-track:after {
	clear: both;
}

.slick-loading .slick-track {
	visibility: hidden;
}

.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
}

[dir='rtl'] .slick-slide {
	float: right;
}

.slick-slide img {
	display: block;
}

.slick-slide.slick-loading img {
	display: none;
}

.slick-slide.dragging img {
	pointer-events: none;
}

.slick-initialized .slick-slide {
	display: block;
}

.slick-loading .slick-slide {
	visibility: hidden;
}

.slick-vertical .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
	display: none;
}

.slick-lightbox {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	width: 100%;
	height: 100%;
	background: black;
	-webkit-transition: opacity 0.5s ease;
	transition: opacity 0.5s ease
}

.slick-lightbox .slick-loading .slick-list {
	background-color: transparent
}

.slick-lightbox .slick-prev {
	left: 15px
}

.slick-lightbox .slick-next {
	right: 15px
}

.slick-lightbox-hide {
	opacity: 0
}

.slick-lightbox-hide.slick-lightbox-ie {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0)
}

.slick-lightbox-hide-init {
	position: absolute;
	top: -9999px;
	opacity: 0
}

.slick-lightbox-hide-init.slick-lightbox-ie {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: alpha(opacity=0)
}

.slick-lightbox-inner {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}

.slick-lightbox-slick-item {
	text-align: center;
	overflow: hidden
}

.slick-lightbox-slick-item:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
	margin-right: -0.25em
}

.slick-caption-bottom .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption {
	position: absolute;
	bottom: 0;
	left: 0;
	text-align: center;
	width: 100%;
	margin-bottom: 20px
}

.slick-caption-dynamic .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption {
	display: block;
	text-align: center
}

.slick-lightbox-slick-item-inner {
	display: inline-block;
	vertical-align: middle;
	max-width: 90%;
	max-height: 90%
}

.slick-lightbox-slick-img {
	margin: 0 auto;
	display: block;
	max-width: 100%;
	max-height: 100%
}

.slick-lightbox-slick-caption {
	margin: 10px 0 0;
	color: white
}

.slick-lightbox-close {
	position: absolute;
	top: 15px;
	right: 15px;
	display: block;
	height: 50px;
	width: 50px;
	line-height: 0;
	font-size: 0;
	cursor: pointer;
	background: transparent;
	color: transparent;
	padding: 0;
	border: none
}

.slick-lightbox-close:focus {
	outline: none
}

.slick-lightbox-close:before {
	font-family: "slick";
	font-size: 50px;
	line-height: 1;
	color: white;
	opacity: 0.85;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: '×';
}

.slick-lightbox-close:hover:before {
	opacity: 1;
}

.slick-lightbox .slick-prev,
.slick-lightbox .slick-next {
	width: 60px;
	height: 60px;
}

.slick-lightbox .slick-prev:before,
.slick-lightbox .slick-next:before {
	font-size: 60px;
}

@media (max-width:767px) {

	.slick-lightbox .slick-prev,
	.slick-lightbox .slick-next {
		width: 30px;
		height: 30px;
	}

	.slick-lightbox .slick-prev:before,
	.slick-lightbox .slick-next:before {
		font-size: 30px;
	}
}

/** * EasyZoom core styles */
.easyzoom {
	position: relative;
	display: inline-block;
}

.easyzoom img {
	vertical-align: bottom;
	max-width: none;
}

.easyzoom.is-loading img {
	cursor: progress;
}

.easyzoom.is-ready img {
	cursor: crosshair;
}

.easyzoom.is-error img {
	cursor: not-allowed;
}

.easyzoom-notice {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 150;
	width: 10em;
	margin: -1em 0 0 -5em;
	line-height: 2em;
	text-align: center;
	background: #FFF;
	box-shadow: 0 0 10px #888;
}

.easyzoom-flyout {
	position: absolute;
	z-index: 100;
	overflow: hidden;
	background: #FFF;
}

/** * EasyZoom layout variations */
.easyzoom--overlay .easyzoom-flyout {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.easyzoom--adjacent .easyzoom-flyout {
	top: 0;
	left: 100%;
	width: 100%;
	height: 100%;
	margin-left: 20px;
}

/* end css jquery */
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}

.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	width: 100%;
	/* fix for flashing background */
	-webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	padding: 0 10px;
	-webkit-backface-visibility: hidden;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;

	&:hover {
		z-index: 2;
	}
}

.owl-carousel .owl-item img {
	display: block;
	max-width: 100%;
	-webkit-transform-style: preserve-3d;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
	display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
	cursor: pointer;
	cursor: hand;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel.owl-loaded {
	display: block;
}

.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}

.owl-carousel.owl-hidden {
	opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
	display: none;
}

.owl-carousel.owl-drag .owl-item {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel.owl-grab {
	cursor: move;
	cursor: grab;
}

.owl-carousel.owl-rtl {
	direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
	float: right;
}

/* No Js */
.no-js .owl-carousel {
	display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
	animation-duration: 1000ms;
	animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
	z-index: 0;
}

.owl-carousel .owl-animated-out {
	z-index: 1;
}

.owl-carousel .fadeOut {
	animation-name: fadeOut;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
	transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-item img.owl-lazy {
	transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000;
}

.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	background: url("owl.video.play.png") no-repeat;
	cursor: pointer;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
	-ms-transform: scale(1.3, 1.3);
	transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
	display: none;
}

.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
	height: 100%;
	width: 100%;
}

input:focus,
button:focus,
select:focus,
textarea:focus {
	text-decoration: none !important;
	box-shadow: none !important;
	outline: 0 !important;
}

a {

	&:hover,
	&:focus {
		outline: 0 !important;
		box-shadow: none !important;
		text-decoration: none;
	}
}

.btn-default,
.btn {

	&:hover,
	&:focus,
	&:active,
	&.active {
		outline: 0 !important;
		text-decoration: none !important;
		box-shadow: none !important;
		background: #3e444a;
		border: none;
	}
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.btn-primary.dropdown-toggle.focus,
.open>.btn-primary.dropdown-toggle:focus,
.open>.btn-primary.dropdown-toggle:hover {
	color: $light;
	background-color: #3e444a;
	border-color: transparent;
	border: none;
}

.btn-secondary,
.btn-tertiary,
.btn-primary {
	text-transform: capitalize;
	font-weight: 400;
	font-size: 14px;
	border: none;
	color: #fff;
	line-height: 25px;
	box-shadow: none;
	padding: 5px 20px;
	border-radius: 5px;
	background: $main;

	&:hover {
		background: #3e444a;
		border-color: $main;
		color: $light;
		border: none;
	}
}

.btn-secondary:hover,
.btn-tertiary:hover,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
	color: $light;
	background: #3e444a;
	border-color: transparent;
	border: none;
}

a {
	color: #3e444a;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.img-responsive {
	display: block;
	max-width: 100%;
	height: auto;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: none;
}

/* edit from here */
.ui-menu .ui-menu-item a.ui-state-focus,
.ui-menu .ui-menu-item a {
	font-size: 12px;
}

.ui-menu .ui-menu-item a.ui-state-focus,
.ui-menu .ui-menu-item a.ui-state-active {
	margin: 0;
	background: none;
	border: 0;
	color: $main;
	cursor: pointer;
}

button {
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.product-flag {
	margin: 0;
}

/* edit theme */
* {
	&:focus {
		outline: none !important;
	}
}

img {
	max-width: 100%;
	margin: auto;
	height: auto;

}

ol,
ul,
dl {
	margin: 0;
	padding: 0;
}

i,
cite,
em,
var,
address,
dfn {
	font-style: normal;
}

body {
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	line-height: 18px;
	color: #3e444a;
}

p {
	font-size: 14px;
	color: #3e444a;
	margin: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 16px;
	text-transform: capitalize;
	color: #333333;
	font-weight: 700;
	font-family: 'Lato', sans-serif;
}

.unvisible,
.headerSpace {
	display: none;
}

/* custom page */
.card {
	box-shadow: none;
	border: none;
	padding: 0;
	text-align: justify;
}

.page-header,
.card-block {
	h1 {
		color: #333333;
		text-align: left;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		text-transform: none;
		border-bottom: $main_border;
		margin-bottom: .8em;
		padding-bottom: .5em;
	}
}

.page-content.page-cms {
	padding: 1.25rem 0;

	p {
		line-height: 20px;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.contact-form {
	padding: 0 0 0 50px;
	color: #3e444a;
	border-left: $main_border;
}

.form-control {
	background-color: $light;
	color: #3e444a;
	border: $main_border;
	font-size: 14px;
}

.form-control:focus {
	color: #3e444a;
	border-color: #333333;
}

.form-control:focus,
.input-group.focus {
	outline: 0;
}

.input-group .input-group-btn>.btn[data-action=show-password] {
	&:hover {
		background: $main;
	}
}

.form-control-label {
	font-size: 14px;
	text-align: left;
	color: #3e444a;
}

.input-group .input-group-btn>.btn {
	padding: .67rem 1rem;
}

.sitemap h2 {
	color: #3e444a;
	text-transform: capitalize;
	font-size: 16px;
	font-weight: 500;
	border-bottom: $main_border;
}

.sitemap ul li {
	font-size: 14px;

	&:before {
		content: "\f105";
		font-family: "Font Awesome 5 Free";
		font-size: 14px;
		display: inline-block;
		margin-right: 5px;
		font-weight: 900;
	}
}

#stores .page-stores {
	width: 100%;
}

#stores .page-stores .store-item {
	padding: 0;
}

#stores .page-stores .store-item-container {
	border-bottom: 1px solid hsla(0, 0%, 63%, .25);

	.h3.card-title {
		font-size: 16px;
		color: #333333;
		text-transform: capitalize;
	}
}

#stores .page-stores .store-item-container .store-description {
	font-size: 14px;
	color: #666;
}

#stores .page-stores .store-item-container ul {
	margin-bottom: 0;
	font-size: 14px;
}

#stores .page-stores .store-item-container .divide-left {
	border-left: 1px solid hsla(0, 0%, 63%, .25);
}

.page-addresses .address,
.page-authentication #content {
	box-shadow: none;
	background: #fff;
	border: $main_border;
}

.page-authentication #content {
	max-width: 100%;
}

.page-customer-account #content {
	box-shadow: none;
	border: $main_border;
	font-size: 14px;
	color: #3e444a;
}

.page-customer-account #content .no-account {
	text-align: center;
	font-size: 14px;
}

.custom-checkbox input[type=checkbox]+span {
	margin-top: 5px;
}

.label,
label {
	font-size: 14px;
	color: #3e444a;
}

.page-my-account #content .links a span.link-item {
	box-shadow: none;
	border: $main_border;
	font-size: 14px;
}

.separator {
	border-color: #d9d9d9;
}

.cart-summary,
#js-checkout-summary {
	border: $main_border;
}

.cart-summary-line .label,
.cart-summary .label,
.cart-summary-line.cart-summary-subtotals .label,
.cart-summary-line.cart-summary-subtotals .value {
	text-transform: capitalize;
	font-weight: 600;
}

.cart-summary-line .value {
	color: #333333;
	font-weight: 600;
	font-size: 16px;
}

.cart-summary-products p {
	font-weight: 600;
}

.cart-summary,
#checkout-payment-step {
	.btn-primary {
		font-weight: 600;
		font-size: 16px;
		padding: 10px 20px;
	}
}

#checkout-payment-step {
	.btn-primary {
		margin-top: 10px;
	}
}

.cart-grid-body .card-block {
	padding: 0;
}



.cart-item {
	border-bottom: $main_border;
}

body#checkout section.checkout-step {
	box-shadow: none;
	border-bottom: $main_border;
}

body#checkout section.checkout-step .step-title {
	text-transform: capitalize;
}

.checkout-inner {
	border: $main_border;
}

.block-reassurance-item {
	.h6 {
		font-size: 14px;
	}
}

/* end custom page */
@media (min-width:1200px) {
	.container-fluid {
		padding-left: 80px;
		padding-right: 80px;
	}
}

@media (max-width:991px) {
	.container {
		width: 100%;
	}
}

main {
	overflow: hidden;
}

#wrapper {
	margin: 0;
	background: transparent;
	box-shadow: none;
	padding: 0;
	padding-top: 30px;

	#main .page-footer {
		margin: 0;
	}
}

#index {
	#wrapper {
		padding-top: 0;
	}

	#header .header-bottom {
		@include screenL {
			box-shadow: 0 1px 0px 0px rgba(0, 0, 0, 0.1);
		}
	}
}

.header-contact-details {
	float: right;
	margin-left: 20px;

	@media (max-width: 550px) {
		display: grid;
    	grid-template-columns: repeat(1, auto);
	}

	a {
		text-decoration: underline;

		&:hover,
		&:focus {
			color: $main;
		}
	}

	span {
		display: inline-block;
		margin: 0 8px;
		text-align: left;

		@media (max-width: 550px) {
			margin-right: 0;
			text-align: right;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	i {
		font-size: 21px;
		@media (max-width: 550px) {
			display: none;
		}
	}
}

.back-top {
	display: block;
	position: fixed;
	right: 25px;
	bottom: 25px;
	overflow: hidden;
	z-index: 10;

	@media(min-width: 1920px) {
		right: 50px;
		bottom: 50px;
	}

	a {
		background: $dark;
		color: $light;
		width: 40px;
		height: 40px;
		line-height: 40px;
		border-radius: 100%;
		display: block;
		text-align: center;
		text-decoration: none;
		-webkit-transition: all 500ms ease-in-out;
		-moz-transition: all 500ms ease-in-out;
		-o-transition: all 500ms ease-in-out;
		transition: all 500ms ease-in-out;

		&:after {
			content: "\f062";
			font-family: "Font Awesome 5 Free";
			font-size: 25px;
			font-weight: 900;
		}

		&:hover {
			background: $main;
		}
	}
}

/* ===== edit theme ======== */
@media(min-width: 992px) {
	#header .header-bottom.scroll-menu {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1001;
		background: rgba(255, 255, 255, 0.7);
		border: none;
		@include pos-transition(300ms);
		box-shadow: 0px 3px 10.23px 0.77px rgba(0, 0, 0, 0.1);

		&:hover {
			background: rgba(255, 255, 255, 1);
			@include pos-transition(300ms);
		}
	}
}

#header {
	position: relative;
	z-index: 9;
	background: $light;

	@media(max-width: 767px) {
		text-align: center;
	}

	.header-nav {
		background: transparent;
		max-height: none;
		margin: 0;

		.container {
			position: relative;
			padding-top: 15px;
			padding-bottom: 15px;

			&:before {
				content: "";
				position: absolute;
				bottom: -1px;
				left: 15px;
				right: 15px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			}
		}

		@media(max-width: 991px) {
			text-align: center;
		}
	}

	.header-top {
		padding: 30px 0;

		@media(max-width: 767px) {
			background: transparent;
			padding: 10px 0;
		}

		@media(min-width: 992px) {
			.row {
				margin: 0;
				display: table;
				vertical-align: middle;
				width: 100%;

				.col {
					padding: 0;
					display: table-cell;
					vertical-align: middle;
					float: none;

					&.header_logo {
						padding-right: 30px;
					}

				}
			}

		}

		.header_logo {
			img {
				margin: 0;

				@media(max-width: 991px) {
					margin: auto;
					margin-bottom: 20px;
				}

				@media(max-width: 767px) {
					margin-bottom: 0;
				}
			}
		}
	}

	.header-bottom {
		position: relative;
		z-index: 1;

		@media(min-width: 992px) {
			background: $lightgrey;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
			}

			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
			}
		}
	}
}

/* item product */
.pos_title {
	position: relative;
	display: block;
	margin-bottom: 25px;

	&:before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 17px;
		border-bottom: $main_border;
	}

	h2 {
		margin: 0;
		position: relative;
		z-index: 1;
		background: $light;
		padding-right: 20px;
		color: #3e444a;
		font-size: 26px;
		font-weight: 400;
		line-height: 35px;
		display: inline-block;
		text-transform: capitalize;

		@media (max-width: 1199px) {
			font-size: 18px;
		}
	}

	.firsttitle {
		font-weight: 700;
		color: $main;
	}
}

.js-product-miniature {
	position: relative;
	background: $light;
	border: $main_border;
	border-radius: 5px;
	overflow: hidden;
	@include pos-transition(300ms);
	padding-bottom: 15px;
	margin-bottom: 20px;
	min-height: 410px;

	&:last-child {
		margin-bottom: 0;
	}

	.box-top {
		padding: 10px 20px;
		height: 80px;
		@include pos-transition(300ms);
	}

	h1 {
		font-weight: 400;
		margin: 0;
		line-height: 1;
	}

	.product_name {
		line-height: 20px;
		min-height: 40px;
		display: block;
		overflow: hidden;
		color: $secondary;
		font-size: 14px;
		margin-top: 15px;
		margin-bottom: 5px;

		&:hover {
			color: $main;
		}
	}

	.manufacturer a {
		color: #878787;
		font-size: 12px;
		text-transform: uppercase;
		height: 0;
		line-height: 18px;
		opacity: 0;
		overflow: hidden;

		&:hover {
			color: $main;
		}
	}

	.img_block {
		position: relative;
		overflow: hidden;

		img {
			width: 100%;
			margin: 0;
			@include pos-transition(300ms);
		}

	}

	&:hover {
		.manufacturer a {
			height: 18px;
			opacity: 1;
			overflow: visible;
		}

		.product_name {
			margin-top: 0;
		}

		div.cart {

			button.ajax_add_to_cart_button,
			span.ajax_add_to_cart_button {
				opacity: 1;
				@include pos-transform(1);
				@include pos-transition(300ms);
			}
		}

		.add-to-links {
			li {
				opacity: 1;
				@include pos-transform(1);

				&:nth-child(1) {
					@include pos-transition(300ms);
				}

				&:nth-child(2) {
					@include pos-transition(400ms);
				}

				&:nth-child(3) {
					@include pos-transition(500ms);
				}

			}
		}
	}

	.add-to-links {
		position: absolute;
		top: 10px;
		right: 10px;

		li {
			float: left;
			clear: both;
			height: 40px;
			margin-bottom: 5px;

			@media (max-width:1199px) {}

			opacity:0;
			@include pos-transform(0);
			@include pos-transition(300ms);

			&:last-child {
				margin: 0;
			}

			&.compare {
				display: none;
			}

			a {
				display: inline-block;
				background: transparent;
				padding: 0;
				line-height: 40px;
				border-radius: 100px;
				background: $secondary;
				height: 40px;
				color: #fff;
				width: 40px;
				padding: 0;
				font-size: 0;
				text-align: center;
				text-transform: capitalize;

				&:before {
					font-size: 20px;
					font-family: 'Linearicons-Free';
					display: block;
					font-weight: 400;
				}

				&:hover {
					@include pos-transition(300ms);
					background: $main;
				}

				&.quick_view:before {
					content: "\e86f";
				}

				&.addToWishlist:before {
					content: "\e813";
				}

				&.compare-button:before {
					content: "\e862";
				}
			}

		}
	}

	div.cart {
		position: absolute;
		right: 10px;
		bottom: 12px;
		height: 40px;

		button.ajax_add_to_cart_button,
		span.ajax_add_to_cart_button {
			display: inline-block;
			background: transparent;
			color: $secondary;
			line-height: 40px;
			height: 40px;
			width: 40px;
			padding: 0;
			cursor: pointer;
			font-size: 0;
			border: none;
			text-transform: uppercase;
			@include pos-transform(0);
			opacity: 0;
			@include pos-transition(300ms);

			&:before {
				font-size: 22px;
				font-family: 'Font Awesome 5 Free';
				display: block;
				font-weight: 600;
				content: "\f07a";
			}

			i {
				font-size: 14px;
				margin-right: 5px;
				display: none;
			}

			&.disabled {
				opacity: 0.5;
			}

			&:hover {
				color: $main;
				@include pos-transition(300ms);
			}
		}
	}

	.product_desc {
		padding: 20px 60px 20px 20px;
		background: $light;
		overflow: hidden;
		position: relative;

		.hook-reviews {
			display: block;
			line-height: 1;
			margin-bottom: 15px;
			display: block;
			@include pos-transition(300ms);

			.comments_note {
				direction: ltr !important;
				text-align: left;
			}

			.star_content {
				display: inline-block;
				vertical-align: bottom;

			}

			.nb-comments {
				font-size: 12px;
				display: none;
			}
		}

		.product-desc {
			display: none;
			padding: 20px 0;
			font-size: 14px;
			color: #7a7a7a;
			line-height: 24px;
			margin: 0;

			p {
				font-size: 14px;
				color: #7a7a7a;
				line-height: 24px;
				margin: 0;

				span {
					font-family: 'Open Sans', sans-serif !important;
					font-size: 14px !important;
				}
			}
		}

		.variant-links {
			margin-top: 15px;
			margin-top: 15px;
			background: transparent;
			padding: 0;
			min-height: auto;
			text-align: left;
			display: none;

			a {
				margin: 0 5px 0 0;
				margin: 0 5px 0 0;
				border-radius: 100%;
				width: 15px;
				height: 15px;
			}
		}


	}
}

.cmp_added {
	color: $main !important;
}

.js-product-miniature .product-price-and-shipping {
	line-height: 1;
	text-align: left;

	.sr-only {
		display: none;
	}

	.price {
		font-size: 18px;
		font-weight: 600;
		color: #3e444a;

		&.price-sale {
			color: #fd5018;
		}
	}

	.regular-price {
		font-size: 16px;
		color: #757575;
		font-weight: 400;
		text-decoration: line-through;
		margin-right: 5px;
	}

}

.product-price-and-shipping-top .discount-product {
	position: absolute;
	top: 5px !important;
	left: 20px;
	display: inline-block;
	background: $main;
	color: #fff;
	border-radius: 5px;
	padding: 0 8px;
	min-width: 50px;
	text-align: center;
	line-height: 23px;
	height: 23px;
	font-size: 12px;
	font-weight: 400;
	text-transform: capitalize;
	-moz-transform: skewX(-25deg);
	-webkit-transform: skewX(-25deg);
	-o-transform: skewX(-25deg);
	-ms-transform: skewX(-25deg);
	transform: skewX(-25deg);
	z-index: 3;

	span {
		-moz-transform: skewX(25deg);
		-webkit-transform: skewX(25deg);
		-o-transform: skewX(25deg);
		-ms-transform: skewX(25deg);
		transform: skewX(25deg);
		display: block;
	}
}

.product-flag {

	.new,
	.on-sale,
	.pack {
		position: absolute;
		top: 5px !important;
		left: 20px;
		display: inline-block;
		background: $main;
		color: #fff;
		font-weight: 600;
		border-radius: 5px;
		padding: 0;
		min-width: 50px;
		text-align: center;
		line-height: 23px;
		height: 23px;
		font-size: 12px;
		text-transform: capitalize;
		-moz-transform: skewX(-25deg);
		-webkit-transform: skewX(-25deg);
		-o-transform: skewX(-25deg);
		-ms-transform: skewX(-25deg);
		transform: skewX(-25deg);

		span {
			-moz-transform: skewX(25deg);
			-webkit-transform: skewX(25deg);
			-o-transform: skewX(25deg);
			-ms-transform: skewX(25deg);
			transform: skewX(25deg);
			display: block;
		}
	}
}

.product-flag .pack {
	z-index: 2;
}

.product-flag .on-sale {
	top: 35px !important;

	&:before {
		background: #f8484a;
	}

	span {
		background: #f8484a;
	}
}

.product-flag .discount {
	display: none;
}

.owl-dots {
	position: absolute;
	right: 15px;
	top: -50px;

	.owl-dot {
		display: inline-block;
		margin: 0 4px;

		&:last-child {
			margin-right: 0;
		}

		span {
			display: inline-block;
			width: 8px;
			height: 8px;
			border-radius: 8px;
			background: #cacaca;
			@include pos-transition(300ms);

			&:hover {
				background: #444444;
				@include pos-transition(300ms);
			}
		}

		&.active {
			span {
				background: #444444;
				@include pos-transition(300ms);
			}
		}
	}
}

.owl-nav {
	position: absolute;
	top: -60px;
	right: 10px;
	background: $main;
	padding: 0 10px;
	border-radius: 25px;
	height: 35px;

	@media (max-width:767px) {
		display: none;
	}

	>div {
		display: inline-block;
		font-size: 0;
		cursor: pointer;
		text-align: center;
		height: 33px;
		width: 33px;
		line-height: 33px;
		color: #fff !important;
		margin: 1px 0 0;
		z-index: 1;
		@include pos-transition(300ms);


		&:before {
			font-size: 21px;
			display: block;
			font-family: "Ionicons";
		}

		&:hover {
			@include pos-transition(300ms);
			color: $main;
		}

		@include pos-transition(300ms);

		&.owl-prev {

			&:before {
				content: "\f3d5";
			}
		}

		&.owl-next {
			&:before {
				content: "\f3d6";
			}
		}

	}
}

.pos_content {
	position: relative;
	margin: 0 -10px;

	&:hover {
		.owl-nav {
			>div {
				opacity: 1;
			}
		}
	}

	.js-product-miniature,
	.thumb-category,
	.item_logo,
	.thumb-container {
		img {
			display: none;
		}
	}

	.owl-item {

		.js-product-miniature,
		.thumb-category,
		.item_logo,
		.thumb-container {
			img {
				display: block;
			}
		}
	}
}

.supplier-logos {
	margin-top: 20px;

	@media (max-width: 600px) {
		display: none;
	}
}

.category-products-slider,
.pos-featured-products,
.categoryproducts,
.product-accessories,
,
.pos-bestsellers-product,
.poslistcateproduct,
.tab-category-container-slider,
.tab-category-container-slider2,
.tab-category-container-slider3,
.pos_new_product,
.product-tabs-container-slider,
.pos-special-products,
.pos_random_product,
.poslistcategories,
.home_blog_post_area,
.testimonials_container,
.recommended-product {
	margin-bottom: 50px;
	position: relative;
	display: inline-block;
	width: 100%;
	background: $light;
}

/* end item product */
/* selector */
.selector-block {
	float: right;
	padding: 0 18px;
	line-height: 1;
	border-right: 1px solid #232323;

	@media (max-width:767px) {
		display: inline-block;
		float: left;
		border: none !important;
		padding: 0 10px 0 0;
	}

	&.language-selector-wrapper {
		border: none;

		@media (min-width:992px) {
			padding-right: 0;
		}
	}

	.settings-icon {
		display: none;
		font-size: 21px !important;
		transform: translateY(1px);

		@media (max-width: 550px) {
			display: inline-block;
		}
	}

	.expand-icon {
		@media (max-width: 550px) {
			display: none;
		}
	}

	.selector-label {
		color: #3e444a;
		line-height: 16px;
		text-transform: capitalize;
		font-size: 13px;
		margin-right: 5px;
		display: none;
	}

	.localiz_block {
		margin: 0 !important;
		display: inline-block;

		&:hover {

			button,
			.current-block {
				color: $main;
				@include pos-transition(300ms);

				.expand-more {
					color: $main;
					@include pos-transition(300ms);
				}
			}
		}

		button,
		.current-block {
			font-weight: 400;
			color: #3e444a;
			background: transparent;
			text-shadow: none;
			cursor: pointer;
			line-height: 16px;
			font-size: 13px;
			display: inline-block;
			text-transform: capitalize;
			@include pos-transition(300ms);

			.expand-more {
				color: #3e444a;
				@include pos-transition(300ms);

				@media (max-width: 550px) {
					display: none;
				}
			}

			i {
				font-size: 14px;
				vertical-align: -3px;
			}

			img {
				margin-right: 5px;
				vertical-align: -1px;
			}

		}

		.dropdown-menu {
			margin: 0;
			top: 32px;

			@media (max-width:320px) {
				top: 20px;
			}

			right: auto;
			left: 0;
			min-width:130px;
			overflow:hidden;
			padding:0 20px;
			background:$light;
			border-radius: 0;
			border:none;
			box-shadow: 0px 3px 10.23px 0.77px rgba(0, 0, 0, 0.1);

			li {
				padding: 0;
				@include pos-transition(300ms);

				&:last-child {
					.dropdown-item {
						border-bottom: none;
					}
				}

				.dropdown-item {
					padding: 0 10px;
					color: #3e444a;
					line-height: 45px;
					font-size: 13px;
					border-bottom: $main_border;

					img {
						display: inline-block;
						margin-right: 5px;
						vertical-align: -2px;
					}
				}

				&.current .dropdown-item {
					color: $main;
					@include pos-transition(300ms);
				}

				&:hover .dropdown-item {
					color: $main;
					@include pos-transition(300ms);
				}
			}
		}
	}
}

.user-info-block {
	@media (min-width:992px) {
		float: right;
	}

	@media (max-width: 550px) {
		padding: 0;
	}

	.localiz_block {

		button,
		.current-block {
			text-transform: capitalize;

			.icon.icon-Settings {
				font-size: 16px;
				vertical-align: -2px;
				margin-right: 5px;
				display: none;
			}
		}
	}
}

/* end selector */
/* wishlist block */
.wishtlist_Top {
	float: right;
	margin-right: 25px;

	@media (max-width:767px) {
		float: none;
		display: inline-block;
		margin: 0 10px 0 0;
	}

	@media (max-width:479px) {
		width: 100%;
		margin: 0;

	}

	a {
		height: 48px;
		line-height: 48px;
		color: #3e444a;
		position: relative;
		text-align: center;
		display: inline-block;
		font-size: 13px;

		&:hover {
			color: $main;
		}

		.lnr {
			font-size: 32px;
			margin-right: 5px;
			vertical-align: middle;
		}

		.lnr-heart {
			&:before {
				font-family: "Font Awesome 5 Free";
				content: '\f004';
				font-weight: 500;
				font-size: 29px;
			}
		}

		.txt_wishlist {
			display: inline-block;
			line-height: 1;
			vertical-align: -10px;
		}

		.cart-wishlist-number {
			position: absolute;
			top: 5px;
			left: 40px;
			display: block;
			overflow: hidden;
			font-weight: 500;
			color: #fff;
			font-size: 12px;
			line-height: 20px;
			width: 20px;
			height: 20px;
			background: $main;
			border-radius: 100%;
		}
	}
}

.popover-content .table {
	background: $light;
	border: $main_border;

	td,
	th {
		padding: 5px;
	}

	td {
		cursor: pointer;
	}

}

/* end wishlist block */
/* compare */
.compare_top {
	a {
		font-weight: 400;
		color: #3e444a;
		background: transparent;
		text-shadow: none;
		cursor: pointer;
		line-height: 16px;
		font-size: 13px;
		display: inline-block;

		&:hover {
			color: $main;
		}
	}
}

/* end compare */
/* module contact */
.contact-link {
	float: right;
	line-height: 1;

	@media (max-width:991px) {
		display: inline-block;
		width: 100%;
		float: none;
		margin-top: 10px;
	}

	.info_box {
		position: relative;
		line-height: 16px;
		text-shadow: none;
		font-size: 14px;
		display: inline-block;
		font-weight: 400;
		color: $light;
		padding: 0 18px;
		border-right: 1px solid rgba(255, 255, 255, 0.3);

		.icon {
			margin-right: 5px;
			font-size: 16px;
			vertical-align: -2px;
		}

		a {
			color: $light;

			&:hover {
				color: $main;
			}
		}
	}
}

/* end module contact */

/* module user-info */
.user_info_top {
	float: right;
	font-size: 0;

	@media (max-width:767px) {
		width: 100%;
		display: inline-block;
		float: none;
	}

	>li {
		display: inline-block;
		vertical-align: top;
		margin: 0 5px;
		line-height: 1;

		&:last-child a {
			border: none;
		}

		a {
			display: inline-block;
			overflow: hidden;
			float: left;
			line-height: 35px;
			padding: 0 15px 0 30px;
			font-size: 14px;
			position: relative;
			color: #3e444a;

			i {
				position: absolute;
				top: 0;
				left: 3px;
				display: block;
				font-size: 14px;
				line-height: 35px;
				color: #999999;
				@include pos-transition(300ms);
			}

			&:hover {
				color: $main;

				i {
					left: 7px;
					color: $main;
					@include pos-transition(300ms);
				}
			}
		}
	}
}

/* end module user-info */
/* module megamenu */
.megamenu {
	position: relative;
	z-index: 1;
}

.pt_custommenu {
	position: relative;

	.pt_menu {
		float: left;

		.parentMenu a {
			display: block;
			line-height: 25px;
			padding: 15px 0;
			margin: 0 25px;

			@media (max-width:1199px) {
				margin: 0 13px;
			}

			font-size: 14px;
			font-weight: 800;
			color: #3e444a;
			background: transparent;
			text-transform: uppercase;
			position: relative;
			font-family: 'Lato',
			sans-serif;

			&:before {
				content: "";
				position: absolute;
				height: 2px;
				left: 50%;
				right: 50%;
				bottom: -1px;
				background: $main;
				@include pos-transition(300ms);
			}

			img {
				margin: 0;
			}

			i {
				font-size: 11px;
				padding: 0 0 0 7px;
				font-family: "Ionicons";

				&:before {
					content: "\f3d0";

					font: {
						size: 12px;
						weight: 400;
					}
				}
			}

		}

		&.act .parentMenu a,
		&.active .parentMenu a {
			&:before {
				left: 0;
				right: 0;
				@include pos-transition(300ms);
			}
		}

		&:first-child .parentMenu a {
			margin-left: 0;
		}

		.popup {
			position: absolute;
			box-shadow: 0px 3px 10.23px 0.77px rgba(0, 0, 0, 0.1);
			border-top: none;
			background: $light;
			padding: 30px 0;
			z-index: 10;
			text-align: left;
			min-width: 280px;

			.cms_mega a img {
				-webkit-transition: all 1700ms ease;
				-moz-transition: all 1700ms ease;
				-o-transition: all 1700ms ease;
				transition: all 1700ms ease;
			}

			.cms_mega a:hover img {
				-webkit-transform: scale(1.07);
				-moz-transform: scale(1.07);
				-ms-transform: scale(1.07);
				-o-transform: scale(1.07);
				transform: scale(1.07);
			}

			.block1 {
				overflow: hidden;
				float: left;

				.column {
					float: left;
					width: 200px + 60px;
					padding-left: 30px;
					padding-right: 30px;
					margin-bottom: -99999px;
					padding-bottom: 99999px;
					border-right: $main_border;

					&.last {
						border: none;
						margin-right: 0;
					}

					.itemMenuName {
						color: #3e444a;
						line-height: 35px;
						text-transform: uppercase;
						font-weight: 600;
						font-size: 14px;
						display: block;

						&:hover {
							color: $main;
						}
					}

					.itemSubMenu {
						padding: 0;

						.itemMenuName {
							color: #757575;
							text-transform: capitalize;
							line-height: 30px;
							font-weight: 400;
							font-size: 14px;

							&:hover {
								color: $main;
							}
						}
					}
				}
			}

			.block2 {
				padding: 30px 30px 0 30px;
				display: inline-block;
				width: 100%;

				.banner-box {
					margin-bottom: 0;
				}
			}
		}
	}

}

.pt_custommenu_mobile {
	text-align: left;
	position: relative;
	clear: both;

	.navbar {
		min-height: 40px;
		margin: 0;
		border: 0;
		position: relative;
		overflow: hidden;
		background: #333333;
		padding: 0 15px;
		overflow: hidden;
		cursor: pointer;

		.navbar-inner .btn-navbar {
			display: block;
			overflow: hidden;
			margin: 0;
			color: #fff;
			font-size: 16px;
			font-weight: 600;
			letter-spacing: 0.025em;
			text-transform: uppercase;
			line-height: 40px;

			&:after {
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				width: 40px;
				height: 40px;
				text-align: center;
				color: #fff;
				font-size: 15px;
				font-weight: 400;
				font-family: "Font Awesome 5 Free";
				cursor: pointer;
				font-weight: 900;
			}
		}

		.navbar-inner.navbar-inactive .btn-navbar:after {
			content: "\f03a";
		}

		.navbar-inner.navbar-active .btn-navbar:after {
			content: "\f03a";
		}
	}
}

ul#pt_custommenu_itemmobile {
	overflow: hidden;
	margin: 0 0 15px;
	padding: 0;

	li {
		cursor: pointer;
		list-style-type: none;
		margin: 0;
		padding: 10px 45px 10px 0;
		display: block;
		position: relative;
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);

		&:last-child {
			border: 0;
		}

		a {
			line-height: 1;
			color: #fff;
			text-transform: capitalize;
			text-decoration: none;
			font-size: 15px;
			font-weight: 400;

			&:hover {
				color: $main;
			}
		}

		.grower {
			position: absolute;
			top: 0;
			right: 0;

			a:after {
				display: block;
				width: 45px;
				height: 45px;
				line-height: 40px;
				text-align: center;
				color: #fff;
				font-size: 14px;
				font-weight: 400;
				font-family: "Font Awesome 5 Free";
				content: "\f055";
				font-weight: 900;
			}

			&.active a:after {
				content: "\f056";
			}
		}

	}
}

/* end module megamenu */
/* module vegamenu */
.navleft-container {
	position: relative;
	z-index: 9;

	.pt_vmegamenu_title {
		position: relative;
		overflow: hidden;
		cursor: pointer;
		background: $main;
		height: 55px;
		padding: 0 20px 0 0;

		h2 {
			margin: 0;
			color: #fff;
			line-height: 55px;
			padding: 0;
			text-transform: uppercase;
			font-size: 16px;
			font-weight: 600;

			&:before {
				display: block;
				float: left;
				content: '\e898';
				color: #fff;
				line-height: 55px;
				font-family: 'Linearicons-Free';
				font-size: 24px;
				font-weight: normal;
				width: 50px;
				text-align: center;
			}

			i {
				display: none;
			}
		}
	}

	.pt_vmegamenu {
		display: none;
		width: 100%;
		background: #fff;
		border: $main_border;
		border-top: 0;
		padding: 12px 0;
		position: absolute;
		top: 100%;
		left: 0;

		.pt_menu {
			position: relative;

			&:hover .wrap-popup {
				opacity: 1;
			}

			&:not(.noSub):after {
				display: block;
				position: absolute;
				top: 0;
				right: 15px;
				line-height: 40px;
				content: "\f3d3";
				font-family: "Ionicons";

				font: {
					size: 12px;
					weight: 400;

				}

				@media (max-width:1199px) {
					line-height: 32px;
				}
			}

			&.active .parentMenu a,
			&:hover:not(.noSub):after {
				color: $main;

			}

			&:last-child .parentMenu a {
				border-bottom: 0;
			}

			.parentMenu a {
				position: relative;
				display: block;
				overflow: hidden;
				line-height: 20px;
				text-transform: capitalize;
				word-wrap: break-word;
				background: $light;
				color: #3e444a;
				padding: 10px 20px;

				@media (max-width:1199px) {
					padding: 8px 20px;
				}

				font: {
					size: 14px;
					weight: 600;

				}

				.cate-thumb {
					float: left;
					width: 24px;
					margin-top: 2px;
					margin-right: 10px;
				}

				span:not(.cate-thumb) {}

				&.view_more {
					&:before {
						display: none;
					}

					cursor:pointer;

					&:hover {
						color: $main;
						@include pos-transition(300ms);
					}

					i {
						margin-right: 7px;
						font-size: 14px;
						width: 30px;
						height: 30px;
						text-align: center;
						line-height: 30px;
					}


				}
			}

			&.act,
			&.active {
				&:after {}

				.parentMenu a {
					color: $main;
					@include pos-transition(300ms);
				}
			}

			.wrap-popup {
				position: absolute;
				top: 0;
				left: 100%;
				z-index: 1;
				opacity: 0;
				width: 200px * 4 + 40px;

				.popup {
					display: none;
					position: absolute;
					top: 0 !important;
					left: 0 !important;
					width: auto !important;
					box-shadow: 0px 3px 10.23px 0.77px rgba(0, 0, 0, 0.1);
					background: #fff;
					min-width: 280px;

					.box-popup {
						background: #fff;
						padding: 20px;
						float: left;
						overflow: hidden;

						.column {
							width: 200px;
							padding: 0 15px;
							margin-bottom: -99999px;
							padding-bottom: 99999px;

							.itemMenuName {
								color: #3e444a;
								line-height: 20px;
								text-transform: uppercase;
								font-weight: 600;
								font-size: 14px;
								display: block;
								padding: 6px 0;

								&:hover {
									color: $main;
								}

							}

							.itemSubMenu {
								padding-bottom: 20px;

								.itemMenuName {
									color: #757575;
									text-transform: capitalize;
									line-height: 30px;
									font-weight: 400;
									font-size: 14px;
									border: 0;
									padding: 0;
									margin: 0;

									&:hover {
										color: $main;
									}

									&:after,
									&:before {
										display: none;
									}
								}
							}

						}

						.column.last {
							padding-right: 0;
							border: 0;

						}

						.column.blockright {
							width: 300px;
						}
					}

					.block1 {
						overflow: hidden;
					}

					.block2 {
						display: inline-block;
						width: 100%;
						margin-top: 20px;
					}
				}
			}
		}


	}
}

/* end module vegamenu */

/* module search category */
#pos_search_top {
	padding: 0;
	position: relative;

	@media (min-width:1200px) {
		max-width: 570px;
	}

	.form_search {
		padding: 0;
		position: relative;
		width: 100%;
		float: left;
		height: 45px;
		color: #a9a9a9;

		label {
			display: none;
		}

		.search-block {
			height: 45x;
			line-height: 45px;
		}

		.form-control {
			padding: 10px 120px 10px 180px;
			width: 100%;
			height: 45px;
			font-size: 14px;
			background: $light;
			color: #3e444a;
			border: none;
			-webkit-border-radius: 25px;
			-moz-border-radius: 25px;
			-ms-border-radius: 25px;
			-o-border-radius: 25px;
			border-radius: 25px;

			@include placeholder {
				color: #a5a5a5;
				opacity: 1;
			}

			&:focus {
				box-shadow: none;
			}

			@media (max-width:479px) {
				padding-right: 0;
			}
		}

		.search_submit {
			position: absolute;
			top: 0;
			right: 0;
			height: 45px;
			line-height: 45px;
			text-align: center;
			text-align: center;
			color: $light;
			background: $secondary;
			padding: 0 35px;
			border: 0;
			font-size: 14px;
			font-weight: 500;
			-webkit-border-radius: 0px 25px 25px 0;
			-moz-border-radius: 0px 25px 25px 0;
			-ms-border-radius: 0px 25px 25px 0;
			-o-border-radius: 0px 25px 25px 0;
			border-radius: 0px 25px 25px 0;
			@include pos-transition(300ms);

			&:hover {
				background: #0658b3;
				@include pos-transition(300ms);
			}

		}

		.form-group {
			position: absolute;
			left: 1px;
			top: 1px;
			display: inline-block;
			width: 150px;
			margin: 0;
			background: transparent;

			&:after {
				content: "";
				width: 1px;
				height: 16px;
				background: #e1e1e1;
				position: absolute;
				top: 50%;
				right: -10px;
				margin-top: -8px;
			}

			.bootstrap-select {
				width: 150px;
				height: 43px;
				line-height: 43px;
				margin: 0;
				border: 0;
				padding: 0 25px;
				color: #3e444a;
				font-size: 14px;
				-webkit-user-select: none;
				-moz-user-select: -moz-none;
				-ms-user-select: none;
				user-select: none;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				background: url(../img/option_search.png) no-repeat 90% 50% $light;
				-webkit-border-radius: 25px;
				-moz-border-radius: 25px;
				-ms-border-radius: 25px;
				-o-border-radius: 25px;
				border-radius: 25px;

			}
		}
	}
}

@media (max-width:480px) {
	#pos_search_top {
		.form_search {
			.form-control {
				font-size: 10px;
				padding: 0 60px 0 120px;
			}

			.form-group {
				width: 110px;

				.bootstrap-select {
					width: 110px;
					font-size: 10px;
					padding: 0 10px
				}

				&:after {
					right: 0;
				}
			}

			.search_submit {
				padding: 0 15px;
			}
		}
	}

}

.ui-corner-all.ui-widget-content {
	max-height: 380px;
	overflow: auto;
	border: $main_border;
}

/* end module search category */
.block-search {
	position: relative;

	@media (min-width:992px) {
		float: right;
	}

	margin-right:30px;

	@media (max-width:1199px) {
		margin-right: 15px;
	}

	@media (max-width:991px) {
		margin: 0;
		padding: 0;
	}

	@media (max-width:767px) {
		margin-top: 20px;
	}
}

.search_top {
	padding: 0;
	background: transparent;
	border: 0;

	form {
		width: 100%;
		position: relative;

		.text_input {
			padding: 10px 65px 10px 20px;
			height: 48px;
			line-height: 28px;
			font-size: 13px;
			border: none;
			border-radius: 5px;
			box-shadow: none;
			overflow: hidden;
			width: 100%;
			background: #f5f5f5;
			color: #9e9e9e;

			@include placeholder {
				color: #9e9e9e;
			}

		}

		button {
			position: absolute;
			top: 0;
			right: 0;
			height: 48px;
			width: 60px;
			display: inline-block;
			padding: 0;
			text-align: center;
			color: #3e444a;
			background: $main;
			line-height: 48px;
			font-size: 22px;
			text-transform: uppercase;
			border: 0;
			text-align: center;
			border-radius: 0px 5px 5px 0px;
			font-weight: 400;
			cursor: pointer;
			@include pos-transition(300ms);

			span {
				line-height: 48px;
			}

			&:hover {
				background: #3e444a;
				color: $light;
				@include pos-transition(300ms);
			}

			.lnr-magnifier {
				&:before {
					font-family: 'Font Awesome 5 Free';
					font-size: 20px;
					content: '\f002';
					font-weight: 600;
					color: #fff;
					display: block;
				}
			}
		}
	}
}

.ui-corner-all {
	overflow: hidden;
	max-height: 300px;
	overflow-y: scroll;

	@media (max-width:360px) {
		max-width: 300px !important;
	}

	li a {
		display: inline-block !important;
		width: 100%;
		overflow: visible;
		max-height: none;
		overflow-y: auto;

		img {
			float: left;
			margin-right: 30px;
			width: 50px;
		}

		>div {
			overflow: hidden;
			padding-top: 10px;
		}

		span {
			margin: 0;
			display: inline-block;
			font-size: 12px;

			&.separator {
				margin-left: 5px;
				margin-right: 5px;
			}

			&.product {
				font-size: 13px;
				font-style: italic;
			}
		}

		.price {
			font-weight: 600;
		}

	}
}

/* end search block */
.blockcart {
	float: right;
	position: relative;

	@media (max-width:767px) {
		float: none;
		display: inline-block;
		margin-top: 15px;
	}

	.header {
		position: relative;
		display: inline-block;
		position: relative;
		height: 48px;

		&:hover a {
			color: $main;
		}

		a {
			display: block;
			position: relative;
			color: #3e444a;
			font-size: 13px;
			font-weight: 400;
			height: 48px;
			line-height: 48px;

			.lnr {
				font-size: 32px;
				margin-right: 5px;
				vertical-align: middle;
			}

			.lnr-cart {
				&:before {
					font-family: "Font Awesome 5 Free";
					content: '\f07a';
					font-weight: 600;
					font-size: 29px;
				}
			}

			.item_txt {
				display: inline-block;
				line-height: 1;
				vertical-align: -10px;
			}

			.item_count {
				position: absolute;
				top: 5px;
				left: 40px;
				display: block;
				overflow: hidden;
				font-weight: 500;
				color: #fff;
				font-size: 12px;
				line-height: 20px;
				width: 20px;
				height: 20px;
				background: $main;
				border-radius: 100%;
				text-align: center;
			}

		}
	}

	.body {
		margin: 0;
		padding: 0;
		width: 340px;

		@media (max-width:479px) {
			width: 270px;
		}

		top:100%;
		left: auto;
		right: 0;
		position:absolute;
		display:none;
		z-index:99;
		text-align:left;
		background:$light;
		box-shadow: 0px 7px 10.67px 0.33px rgba(0, 0, 0, 0.1);

		@media (max-width:767px) {
			right: 50%;
			-moz-transform: translateX(50%);
			-webkit-transform: translateX(50%);
			-o-transform: translateX(50%);
			-ms-transform: translateX(50%);
			transform: translateX(50%);
		}

		ul {
			padding: 0 20px;

			li {
				padding: 20px 0;
				border-bottom: $main_border;
				overflow: hidden;

				.img_content {
					float: left;
					margin: 0 10px 0 0;
					position: relative;

					@media (max-width:480px) {
						width: 76px;
					}

					.product-quantity {
						position: absolute;
						top: 5px;
						left: 5px;
						min-width: 25px;
						line-height: 25px;
						-webkit-border-radius: 100%;
						-moz-border-radius: 100%;
						border-radius: 100%;
						padding: 0;
						text-align: center;
						background: $main;
						color: #3e444a;
						font-size: 14px;
						display: inline-block;
					}
				}

				.right_block {
					overflow: hidden;
					position: relative;
					padding: 0 15px 0 0;

					span {
						font-size: 14px;
					}

					.product-name {
						display: block;
						color: $secondary;
						text-transform: capitalize;
						font-size: 14px;
						line-height: 20px;

						&:hover {
							color: $main;
						}
					}

					.product-price {
						display: block;
						margin: 5px 0 0;
						font-size: 18px;
						font-weight: 600;
						color: #3e444a;
					}

					.remove-from-cart {
						display: block;
						position: absolute;
						top: 0;
						right: -1px;
						color: #a4a4a4;
						font-size: 18px;

						&:hover {
							color: #3e444a;
						}
					}

					.attributes_content {
						display: block;
						font-size: 16px;
						line-height: 20px;
						color: #a4a4a4;
						margin: 5px 0 0;
					}

					strong {
						font-weight: 400;
					}
				}
			}
		}

		.price_content {
			overflow: hidden;
			padding: 20px;

			.price_inline {
				overflow: hidden;
				line-height: 25px;

				.label {
					float: left;
					font-weight: 600;
					color: #3e444a;
					font-size: 16px;
				}

				.value {
					float: right;
					font-weight: 600;
					color: #fd5018;
					font-size: 20px;
				}
			}
		}

		.checkout {
			padding: 20px;
			position: relative;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				border-top: $main_border;
			}

			a {
				display: block;
				text-transform: uppercase;
				font-weight: 700;
				font-size: 15px;
				border: none;
				color: #fff;
				box-shadow: none;
				padding: 10px 15px;
				line-height: 30px;
				border-radius: 100px;
				background: $main;

				&:hover {
					background: #3e444a;
					color: $light;
				}
			}
		}
	}
}



@media (min-width: 992px) {
	.blockcart .body ul {
		max-height: 310px;
		overflow-y: auto;


	}
}

.blockcart .body ul::-webkit-scrollbar-track {
	background-color: $main;
}

.blockcart .body ul::-webkit-scrollbar {
	width: 4px;
	background-color: $main;
}

.blockcart .body ul::-webkit-scrollbar-thumb {
	background-color: rgba(46, 46, 46, 0.85);
	border-radius: 10px;
}

/* style layer cart */
#blockcart-modal .modal-body {
	background: $light;
	padding: 3.125rem 1.875rem;
}

#blockcart-modal .divide-right {
	border-right: $main_border;

	p,
	strong {
		color: #333333;
		font-size: 14px;
		font-weight: 600;
	}
}

#blockcart-modal .cart-content {

	p,
	strong {
		color: #333333;
		font-size: 14px;
	}

	strong {
		font-weight: 600;
	}
}

#blockcart-modal .product-name {
	font-size: 16px;
	margin-bottom: 20px;
	color: #333333;
}

#blockcart-modal .modal-header {
	background: $main;
}

/* end style layer cart */
#blockcart-modal button.close {
	color: $light;
	opacity: 1;
}

#blockcart-modal .cart-content .btn {
	margin-top: 20px;
}

/* end module cart block */

/* module slideshow */
.pos-loading {
	background: #cccccc;
	position: relative;

	span {
		width: 20px;
		height: 20px;
		position: absolute;
		z-index: 99;
		background: url(../img/preloader.gif) no-repeat;
		top: 50%;
		left: 50%;
		margin: -10px 0 0 -10px;
	}

	img {
		opacity: 0;
	}
}

.nivo-html-caption {
	display: none;
}

.timethai {
	display: none;
}

@-webkit-keyframes myfirst {
	from {
		width: 0;
	}

	to {
		width: 100%;
	}
}

@keyframes myfirst {
	from {
		width: 0;
	}

	to {
		width: 100%;
	}
}

.timethai.pos_hover {
	-webkit-animation-play-state: paused;
	animation-play-state: paused;

}

.pos_bannerslide {
	background: $light;
	position: relative;
	z-index: 1;
}

.slideshow_container .pos-slideshow {
	position: relative;
	margin-bottom: 50px;
	border-bottom: 5px solid $main;

	&:hover .nivo-directionNav a {
		opacity: 1;
		@include pos-transition(300ms);
	}
}

.slideshow_container .pos-slideshow .nivo-directionNav a {
	display: block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	color: #e1e1e1;
	text-align: center;
	font-size: 0;
	position: absolute;
	z-index: 8;
	cursor: pointer;
	top: 50%;
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	opacity: 0;
	-webkit-transition: none;
	-moz-transition: none;
	-o-transition: none;
	transition: none;

	i {
		font-size: 36px;
		line-height: 40px;
	}

	@media (max-width:767px) {
		display: none;
	}

	&:hover {
		color: #3e444a;
	}

	&.nivo-prevNav {
		left: 20px;

		@media (min-width:1920px) {
			left: 200px;
		}

	}

	&.nivo-nextNav {
		right: 20px;

		@media (min-width:1920px) {
			right: 200px;
		}
	}
}

.slideshow_container .pos-slideshow .nivo-controlNav {
	position: absolute;
	bottom: 30px;

	@media (max-width:1199px) {
		bottom: get1024-vw(30px);
	}

	left:0;
	right:0;
	text-align:center;
	padding:0 30px;
	z-index: 8;

	@media (max-width: 767px) {
		display: none;
	}

	a {
		display: inline-block;
		position: relative;
		margin: 0 8px;
		cursor: pointer;
		font-size: 0;
		width: 8px;
		height: 8px;
		background: #3e444a;
		-webkit-border-radius: 100%;
		-moz-border-radius: 100%;
		-ms-border-radius: 100%;
		-o-border-radius: 100%;
		border-radius: 100%;
		@include pos-transition(300ms);

		&:before {
			content: "";
			border: 1px solid transparent;
			left: -6px;
			right: -6px;
			top: -6px;
			bottom: -6px;
			border-radius: 100%;
			position: absolute;
		}

		&.active,
		&:hover {
			background: $main;

			&:before {
				border-color: $main;
			}
		}
	}
}

.banner7-des {
	position: absolute;
	left: 0;
	top: 50%;
	right: 0;
	z-index: 8;
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	line-height: 1;
	text-align: left;
	direction: ltr;

	p {
		margin: 0;
	}

	.info {}

	.desc,
	.desc1,
	.desc2 {
		max-width: 52%;

		@media (max-width:480px) {
			max-width: 80%;
		}
	}
}

.desc,
.desc1,
.desc2,
.desc3 {
	font-family: 'Lato', sans-serif;

	&.right-align,
	&.align-right {
		float: right;
	}

	.title1 {
		position: relative;
		font-size: 24px;
		font-style: italic;
		color: #3e444a;
		font-weight: 400;
		display: inline-block;
		margin-bottom: 20px;
		text-transform: capitalize;
		-webkit-animation-duration: 0.8s;
		animation-duration: 0.8s;

		&:before {
			content: "";
			position: absolute;
			left: 100%;
			margin-left: 10px;
			top: 60%;
			width: 70px;
			height: 1px;
			background: #a8a8a8;
		}
	}

	.title2 {
		position: relative;
		font-size: 48px;
		color: #3e444a;
		font-weight: 700;
		text-transform: capitalize;
		-webkit-animation-duration: 1.2s;
		animation-duration: 1.2s;

	}

	.title3 {
		position: relative;
		font-size: 48px;
		color: #3e444a;
		font-weight: 700;
		text-transform: capitalize;
		-webkit-animation-duration: 1.6s;
		animation-duration: 1.6s;
	}

	.title4 {
		position: relative;
		font-size: 48px;
		color: #3e444a;
		margin-bottom: 30px;
		font-weight: 700;
		text-transform: capitalize;
		-webkit-animation-duration: 2s;
		animation-duration: 2s;
	}

	.readmore {
		-webkit-animation-duration: 2.4s;
		animation-duration: 2.4s;
		display: inline-block;

		a {
			@include pos-transition(300ms);
			position: relative;
			font-size: 16px;
			font-weight: 600;
			color: #fff;
			line-height: 50px;
			text-transform: uppercase;
			padding: 0 30px;
			border-radius: 100px;
			background: $main;
			display: inline-block;

			&:hover {
				background: #3e444a;
				color: $light;
				@include pos-transition(300ms);
			}
		}
	}
}

/* responsive slideshow */
@media (max-width:1199px) {

	.desc,
	.desc1,
	.desc2,
	.desc3 {

		.title1 {
			font-size: get1024-vw(20px);
			margin: 0 0 get1024-vw(10px) 0;

			&:before {
				margin-left: get1024-vw(10px);
				width: get1024-vw(70px);
				height: get1024-vw(1px);
			}
		}

		.title2 {
			font-size: get1024-vw(38px);
		}

		.title3 {
			font-size: get1024-vw(38px);
		}

		.title4 {
			font-size: get1024-vw(38px);
			margin: 0 0 get1024-vw(30px) 0;
		}

		.readmore {
			a {
				font-size: get1024-vw(13px);
				line-height: get1024-vw(40px);
				padding: get1024-vw(0px) get1024-vw(25px);
			}
		}
	}
}

@media (max-width:480px) {

	.desc,
	.desc1,
	.desc2,
	.desc3 {
		.title1 {
			font-size: get480-vw(14px);
			margin: 0 0 get480-vw(5px) 0;
		}

		.title2 {
			font-size: get480-vw(16px);
		}

		.title3 {
			font-size: get480-vw(16px);
		}

		.title4 {
			font-size: get480-vw(16px);
			margin: 0 0 get480-vw(10px) 0;
		}

		.readmore {
			a {
				font-size: get480-vw(10px);
				line-height: get480-vw(30px);
				padding: get480-vw(0px) get480-vw(10px);
			}
		}
	}
}

/* end responsive slideshow */


/* end module slideshow */

/* module postabproductslider */
.product-tabs-container-slider {
	background: #f6f6f6;
	padding: 50px 0;

	.pos_title {
		&:before {
			border-color: #e9e9e9;
		}
	}

	ul.tabs_slider {
		display: inline-block;
		background: #f6f6f6;
		position: relative;
		font-family: 'Lato', sans-serif;

		li {
			display: inline-block;
			position: relative;
			z-index: 2;
			padding: 0 20px;
			margin-left: 15px;
			line-height: 35px;
			cursor: pointer;
			color: #777777;
			text-transform: capitalize;

			font: {
				size: 26px;
				weight: 400;
			}

			@media (max-width: 1199px) {
				font-size: 18px;
			}

			.firsttitle {
				font-weight: 700;
			}

			&:before {
				content: "";
				position: absolute;
				right: 100%;
				height: 1px;
				width: 15px;
				top: 50%;
				border-bottom: 1px solid #b2b2b2;
			}

			&:first-child {
				padding-left: 0;
				margin: 0;

				&:before {
					display: none;
				}
			}

			@include pos-transition(300ms);

			&.active,
			&:hover {
				color: #3e444a;
				@include pos-transition(300ms);
			}


		}

	}

	.first-item {
		.js-product-miniature {
			.img_block {
				img {
					display: block;
				}
			}

			@media (max-width: 767px) {
				margin-bottom: 20px;
			}
		}
	}

	.counts-item {
		.js-product-miniature {
			display: inline-block;
			width: 100%;
			padding: 10px 20px;

			.img_block {
				position: relative;
				float: right;
				width: 140px;
				margin: 0 0 0 10px;

				@media (max-width: 1199px) {
					width: 119px;
				}

				@media (max-width: 767px) {
					width: 100px;
				}
			}

			.product_desc {
				overflow: hidden;
				padding: 0;

				.product_name {
					margin-top: 15px !important;
					margin-bottom: 10px;
				}
			}
		}
	}

}



/* end  postabproductslider*/

/* module tabcateslider */
.tab-category-container-slider {
	@media (min-width:992px) {
		.pos_title {
			text-align: center;

			h2 {
				position: absolute;
				left: 0;
				bottom: 0;
			}
		}
	}

	ul.tab_cates {
		display: inline-block;
		background: $light;
		position: relative;
		border: $main_border;
		border-radius: 25px;
		padding: 0 30px;

		@media (min-width:1200px) {
			-moz-transform: translateX(80px);
			-webkit-transform: translateX(80px);
			-o-transform: translateX(80px);
			-ms-transform: translateX(80px);
			transform: translateX(80px);
		}

		@media (min-width:992px) and (max-width:1199px) {
			-moz-transform: translateX(50px);
			-webkit-transform: translateX(50px);
			-o-transform: translateX(50px);
			-ms-transform: translateX(50px);
			transform: translateX(50px);
		}

		li {
			display: inline-block;
			position: relative;
			z-index: 2;
			padding: 0 20px;
			margin-left: 15px;

			@media (max-width:1199px) {}

			line-height:33px;
			cursor:pointer;
			color:#777777;
			text-transform:capitalize;

			font: {
				size: 14px;
				weight: 400;
			}

			&:before {
				content: "";
				position: absolute;
				right: 100%;
				height: 1px;
				width: 15px;
				top: 50%;
				border-bottom: 1px solid #ebebeb;
			}

			&:first-child {
				margin: 0;

				&:before {
					display: none;
				}
			}

			@include pos-transition(300ms);

			&.active,
			&:hover {
				color: #3e444a;
				@include pos-transition(300ms);
			}

			&.active {
				font-weight: 700;
				@include pos-transition(300ms);
			}

		}

	}

	.js-product-miniature {
		.product_desc {
			.add-to-links {
				li {
					margin-right: 2px;

					a {
						line-height: 33px;
						height: 35px;
						width: 35px;
					}

					&.cart {

						button.ajax_add_to_cart_button,
						span.ajax_add_to_cart_button {
							padding: 0 9px;
							font-size: 11px;
							height: 35px;
							line-height: 33px;
						}
					}
				}
			}

		}
	}
}

/* end module tabcateslider */

/* module poslistcateproduct  */
.poslistcateproduct {
	.btn-group {
		position: absolute;
		right: 10px;
		top: 13px;
		z-index: 2;

		>.btn {
			border: 0;
			background: transparent;
			padding: 0;

			&:after {
				display: none;
			}

			&:hover,
			&:active,
			&:focus {
				-webkit-box-shadow: none;
				box-shadow: none;
			}
		}

		.dropdown-menu {
			right: 0;
			left: auto;
			top: 35px;
			border: 0;
			-webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.175);
			box-shadow: 0 1px 4px rgba(0, 0, 0, 0.175);
			padding: 15px;
			min-width: 200px;

			a {
				font-size: 12px;
				color: #666666;
				line-height: 25px;

				&:hover {
					color: $main;
				}
			}
		}
	}

	.subcategories-list {
		@media (min-width:992px) {
			float: right;
		}

		li {
			display: inline-block;

			a {
				display: inline-block;
				position: relative;
				z-index: 2;
				background: $light;
				padding: 0 15px;
				line-height: 50px;
				cursor: pointer;
				color: $secondary;
				text-transform: capitalize;

				font: {
					size: 14px;
					weight: 400;
				}

				@include pos-transition(300ms);

				&.active,
				&:hover {
					color: $main;
					@include pos-transition(300ms);
				}

			}
		}
	}

	.banner-box {
		img {
			width: 100%;
		}
	}

	.pos_content {
		padding: 30px;
	}

	.pos_title {
		margin-bottom: 0;

		.img_thumb {
			display: inline-block;
			width: 50px;
			height: 50px;
			background-color: $main;
			text-align: center;
			padding: 0;

			img {
				width: 25px;
				margin: auto;
			}
		}

		h2 {
			padding: 0;
		}

		span {
			padding: 0 20px;
		}
	}

	.js-product-miniature {
		.product_desc {
			.add-to-links {
				li {
					margin-right: 2px;

					a {
						line-height: 33px;
						height: 35px;
						width: 35px;
					}

					&.cart {

						button.ajax_add_to_cart_button,
						span.ajax_add_to_cart_button {
							padding: 0 9px;
							font-size: 11px;
							height: 35px;
							line-height: 33px;
						}
					}
				}
			}

		}
	}

}

/* end module poslistcateproduct  */
/* module featured-products */

/* end module featured-products */
/* module new-products */
.pos_new_product,
.pos-featured-products {
	.js-product-miniature {
		display: inline-block;
		width: 100%;
		padding: 20px;

		.img_block {
			position: relative;
			float: right;
			width: 140px;
			margin: 0 0 0 10px;

			@media (max-width: 1199px) {
				width: 100px;
			}
		}

		.product_desc {
			overflow: hidden;
			padding: 0;

			.product_name {
				margin-top: 15px !important;
				margin-bottom: 10px;
			}
		}
	}
}

/* end module new-products */

/* end module suggestcateproducts */
.category-products-slider {
	margin-bottom: 0;

	.category_products {
		margin-bottom: 50px;
	}

	.js-product-miniature {
		display: inline-block;
		width: 100%;
		border: none;
		padding: 0;

		.img_block {
			position: relative;
			float: left;
			width: 100px;
			margin-right: 20px;
		}

		.product_desc {
			overflow: hidden;
			padding: 0;

			.product_name {
				margin-top: 0 !important;
				margin-bottom: 5px;
			}
		}
	}
}

/* end module  ssuggestcateproducts */


/* module special-products */
.pos-special-products {
	background: $lightgrey;

	h2 {
		background: $lightgrey;
	}

	padding: 50px 0 60px;
}

/* countdown */
.box-countdown {
	font-family: 'Rubik', sans-serif;
	margin-top: 25px;
	position: relative;
	padding-bottom: 20px;

	&:before {
		content: "";
		height: 5px;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		background: $main;
		border-radius: 10px;
	}

	.title-countdown {
		float: left;
		margin-right: 10px;

		span {
			font-size: 14px;
			line-height: 20px;
			color: #3e444a;
			font-weight: 400;
		}
	}

	.countdown {
		overflow: hidden;
	}
}

.is-countdown {
	display: inline-block;
	width: 100%;
	position: relative;

	.countdown-section {
		display: inline-block;

		.countdown-amount {
			display: inline-block;
			font-size: 14px;
			line-height: 20px;
			color: #3e444a;
			font-weight: 400;
			position: relative;
		}

		.countdown-period {
			display: inline-block;
			font-size: 14px;
			line-height: 20px;
			color: #a4a4a4;

		}

		&:last-child {
			&:after {
				display: none;
			}
		}

		&:after {
			content: ":";
			display: inline-block;
			margin: 0 5px;
		}
	}
}

/* 	.countdown-show1 .countdown-section {
		width: 100%;
	}
	.countdown-show2 .countdown-section {
		width: 50%;
	}
	.countdown-show3 .countdown-section {
		width: 33.33%;
	}
	.countdown-show4 .countdown-section {
		width: 25%;
	}
	#product .countdown-show4 .countdown-section{width: auto;}
	.countdown-show5 .countdown-section {
		width: 19.5%;
	}
	.countdown-show6 .countdown-section {
		width: 16.25%;
	}
	.countdown-show7 .countdown-section {
		width: 14%;
	}  */

/* end countdown */
/* end module special-products */
/* module recommendedproducts */
.recommended-product {}

/*end  module recommendedproducts */
/* module list-categories */
.poslistcategories {
	position: relative;
	background: #f6f6f6;
	padding: 50px 0;

	.pos_title h2 {
		background: #f6f6f6;
	}

	.owl-nav {
		right: 0;
	}

	.pos_content {
		margin: 0;

		.owl-carousel .owl-item {
			padding: 0;

			&.firstActiveItem .list-categories {
				border-left: none;
			}
		}
	}

	.list-categories {
		position: relative;
		background: $light;
		padding: 20px;
		display: inline-block;
		width: 100%;
		border-bottom: $main_border;
		border-left: $main_border;

		&:last-child {
			border-bottom: none;
		}

		&:hover {
			.thumb-category {
				img {
					-moz-transform: scale(1.1) rotate(0.05deg);
					-ms-transform: scale(1.1) rotate(0.05deg);
					-o-transform: scale(1.1) rotate(0.05deg);
					-webkit-transform: scale(1.1) rotate(0.05deg);
					transform: scale(1.1) rotate(0.05deg);
					@include pos-transition(300ms);
					width: 100%;
				}
			}
		}

		.thumb-category {
			@include pos-transition(300ms);
			float: left;
			width: 150px;
			margin-right: 20px;

			@media (max-width:360px) {
				float: none;
				width: 100%;
				display: inline-block;
				margin: 0 0 20px 0;
			}

			a {
				display: block;
				position: relative;
				overflow: hidden;
			}

			img {
				@include pos-transform(1);
				@include pos-transition(300ms);
				width: 100%;
			}
		}

		.desc-listcategoreis {
			overflow: hidden;

			.name_categories {
				margin: 0 0 10px 0;
				font-size: 18px;
				line-height: 1;
				font-weight: 400;
				color: #3e444a;
				text-transform: capitalize;
				@include pos-transition(300ms);

			}

			.sub {
				a {
					color: #757575;
					font-size: 14px;
					line-height: 24px;
					text-transform: capitalize;
					float: left;
					clear: both;

					&:hover {
						padding-left: 10px;
						color: $main;
					}
				}
			}

			.view-more a {
				display: block;
				float: right;
				clear: both;
				margin-top: 10px;
				padding: 0;
				color: #757575;
				line-height: 30px;
				text-transform: uppercase;
				font-size: 13px;
				font-weight: 700;
				margin-right: 8px;

				&:after {
					content: "\f362";
					font-size: 16px;
					display: inline-block;
					font-family: "Ionicons";
					margin-left: 5px;
					vertical-align: -2px;
					color: $main;
					@include pos-transition(300ms);
				}

				&:hover {
					color: $main;

					&:after {
						-moz-transform: translateX(5px);
						-webkit-transform: translateX(5px);
						-o-transform: translateX(5px);
						-ms-transform: translateX(5px);
						transform: translateX(5px);
						@include pos-transition(300ms);
					}
				}
			}
		}
	}
}

/* end module list-categories */

/* blog */
.home_blog_post_area {
	position: relative;
	display: inline-block;
	width: 100%;
	margin-bottom: 40px;

	.pos_title {
		&:before {
			content: "";
			background: url(../img/icon_blog.png) no-repeat $main;
			width: 50px;
			height: 50px;
			display: inline-block;
			vertical-align: top;
		}
	}

	.pos_title a {
		float: right;
		color: $secondary;
		font-size: 14px;
		line-height: 50px;
		text-transform: capitalize;
		margin-right: 15px;

		&:hover {
			color: $main;
		}
	}

	.blog_slider .item {
		.blog_post {
			display: inline-block;
			width: 100%;
			background: $light;

			.blog_post_content_top {}

			.post_content {
				position: relative;
				padding-top: 45px;
			}

			img {
				width: 100%;
			}

			.post_title {
				margin: 0;

				a {
					font-size: 16px;
					font-weight: 500;
					line-height: 18px;
					color: #3e444a;
					text-transform: capitalize;
					position: relative;
					display: block;
					margin-bottom: 10px;

					&:hover {
						color: $main;
					}

				}
			}

			.date_time {
				width: 60px;
				height: 60px;
				background: $light;
				border: 2px solid #ebebeb;
				border-radius: 5px;
				text-align: center;
				position: absolute;
				left: 15px;
				top: -30px;
				padding-top: 12px;

				.day_time {
					display: block;
					color: #3e444a;
					font-size: 18px;
					font-weight: 600;
				}

				.moth_time {
					display: block;
					color: #3e444a;
					font-size: 12px;
					font-weight: 600;
					text-transform: uppercase;
				}
			}

			.post_meta {
				overflow: hidden;

				span {
					color: $main;
					font-size: 14px;
					line-height: 18px;
					text-transform: capitalize;
					display: inline-block;
					margin-right: 20px;

					&:last-child {
						margin: 0;
					}

					i {
						margin-right: 5px;
						color: #5b5b5b;
						vertical-align: 1px;
					}
				}
			}

			.post_description {
				font-size: 14px;
				font-weight: 400;
				color: #3e444a;
				margin: 0;
				display: inline-block;
				width: 100%;
				line-height: 20px;
				padding: 20px 0 0 0;
			}

			a.read-more {
				display: inline-block;
				line-height: 40px;
				border: 1px solid transparent;
				padding: 0 40px;
				border-radius: 30px;
				color: $light;
				background: $main;
				text-transform: uppercase;
				font-size: 12px;
				font-weight: 600;
				margin-top: 30px;

				&:hover {
					color: $main;
					background: $light;
					border-color: $main;
				}
			}
		}
	}
}

.home_blog_post_area .home_blog_post .blog_post .post_thumbnail {
	position: relative;
	overflow: hidden;

	a {
		display: block;
		position: relative;
		overflow: hidden;

		&:before {
			background: rgba(255, 255, 255, 0.3);
			bottom: 0;
			content: "";
			left: 50%;
			position: absolute;
			right: 51%;
			top: 0;
			opacity: 1;
			z-index: 1;

		}

		&:after {
			background: rgba(255, 255, 255, 0.3);
			bottom: 50%;
			content: "";
			left: 0;
			position: absolute;
			right: 0;
			top: 50%;
			opacity: 1;

		}
	}

	&:hover a:before {
		left: 0;
		right: 0;
		opacity: 0;
		@include pos-transition(900ms);
	}

	&:hover a:after {
		top: 0;
		bottom: 0;
		opacity: 0;
		@include pos-transition(900ms);
	}

	img {
		width: 100%;
		@include pos-transition(300ms);
	}
}

.blog_mask {
	display: none;
}

.home_blog_post_area .home_blog_post .blog_post .post_thumbnail .blog_mask .blog_mask_content a {
	display: block;
	text-align: center;
	font-size: 70px;
	color: #fff;
	display: none;
}

.home_blog_post_area .home_blog_post .blog_post .post_thumbnail:hover .blog_mask {
	opacity: 1;
	visibility: visible;
	z-index: 1;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.home_blog_post_area .home_blog_post .blog_post .post_thumbnail:hover .blog_mask .blog_mask_content {
	left: 50%;
}

.kr_blog_post_area .blog_post {
	margin-bottom: 40px;
}

.kr_blog_post_area .blog_post .post_thumbnail img {
	margin: 0;
	width: 100%;
}

.kr_blog_post_area .blog_post .post_content .post_title {
	font-size: 16px;
	text-transform: uppercase;
	margin-bottom: 15px;
}

.kr_blog_post_area .blog_post .post_content .post_meta>p {
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	text-transform: capitalize;
	padding-right: 10px;
	margin-right: 10px;
}

.kr_blog_post_area .blog_post .post_content .read_more a {
	color: #999999;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
}

/* end blog */

/* module logo */
.pos_logo {
	position: relative;
	background: $light;
	overflow: hidden;
	margin-bottom: 60px;

	.item-banklogo {
		pointer-events: none !important;
	}
}

/* end module logo */

/* module newletter */

.ft_newsletter {
	position: relative;

	.desc {
		font-size: 14px;
		color: #666666;
		margin: 0;
		line-height: 20px;
		max-width: 80%;
	}

	form {
		position: relative;
		overflow: hidden;
		margin-top: 30px;

		@media (max-width:991px) {
			margin-top: 20px;
		}

		.input-wrapper {
			input {
				height: 48px;
				background: $light;
				border: 0;
				color: #9e9e9e;
				padding: 10px 120px 10px 25px;
				display: inline-block;
				width: 100%;
				font-size: 13px;
				border: none;
				background: #f0f0f0;
				-webkit-border-radius: 5px;
				-moz-border-radius: 5px;
				-ms-border-radius: 5px;
				-o-border-radius: 5px;
				border-radius: 5px;

				@include placeholder {
					color: #9e9e9e;
					opacity: 1;
				}
			}
		}

		.btn {
			position: absolute;
			top: 0;
			right: 0;
			padding: 10px 20px;
			display: block;
			line-height: 28px;
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 600;
			color: #fff;
			background: $main;
			border: none;
			border-radius: 0px 5px 5px 0px;
			box-shadow: none;
			@include pos-transition(300ms);

			&:hover {
				background: #3e444a;
				color: $light;
				@include pos-transition(300ms);
			}

			span {}
		}

		.condition,
		.alert {
			margin: 10px 0 0;
			font-size: 12px;
			line-height: 24px;
			color: #bcbcbc;
			background: none;
			border: 0;
			padding: 0;

			&.alert-danger {
				color: $main;
			}

			&.alert-success {
				color: $main;
			}
		}
	}
}

/* end module newletter */
/* end module social */
#header .social_follow {
	text-align: left;
	width: auto;
	padding: 0;

	@media (max-width:991px) {
		margin: 0;
	}

	h2 {
		color: #3e444a;
		font-size: 13px;
		line-height: 16px;
		float: left;
		margin: 0 20px 0 0;
		display: block;
		font-weight: 400;
	}

	@media (max-width:767px) {
		text-align: center;
		display: inline-block;
		width: 100%;
		margin-top: 10px;

		h2 {
			margin: 0 0 10px 0;
			display: block;
			float: none;
			display: none;
		}
	}

	ul {
		overflow: hidden;

		li {
			line-height: 16px;
			margin: 0 20px 0 0;

			a {
				height: 16px;
				line-height: 16px;
				border: none;
				width: 16px;

				&:before {
					font-size: 16px;
				}

				&:hover {
					background: transparent;
					color: $main;
				}
			}
		}
	}
}

.social_follow {
	position: relative;
	font-size: 0;
	text-align: right;

	@media (max-width:991px) {
		margin-top: 10px;
		text-align: left;
	}

	h2 {
		display: none;
	}

	li {
		display: inline-block;
		margin: 0 10px 0 0;
		line-height: 40px;
		background-image: none !important;
		padding: 0;

		&:last-child {
			margin: 0;
		}

		a {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			color: #3e444a;
			background: transparent;
			font-size: 0;
			padding: 0;
			line-height: 44px;
			width: 48px;
			height: 48px;
			border-radius: 100%;
			text-align: center;
			border: 1px solid #3e444a;
			z-index: 1;
			@include pos-transition(300ms);

			span {}

			&:before {
				padding: 0 !important;
				font-size: 18px;
				display: inline-block;
				font-family: "Ionicons";
				vertical-align: middle;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				@include pos-transition(300ms);
			}

			&:hover {
				color: $light;
				background: #3e444a;
			}

		}

		&.facebook {
			a {

				&:before {
					content: "\f231";
				}
			}
		}

		&.twitter {
			a {

				&:before {
					content: "\f243";
				}
			}
		}

		&.rss {
			a {

				&:before {
					content: "\f23d";
				}
			}
		}

		&.youtube {
			a {

				&:before {
					content: "\f24d";
				}
			}
		}

		&.googleplus {
			a {

				&:before {
					content: "\f234";
				}
			}
		}

		&.pinterest {
			a {
				&:before {
					content: "\f2b1";
				}
			}
		}

		&.vimeo {
			a {
				&:before {
					content: "\f245";
				}
			}
		}

		&.instagram {
			a {
				&:before {
					content: "\f350";
				}
			}
		}
	}
}

/* end module social */

/* module advertising */
.advertising {
	position: relative;
	margin-bottom: 50px;
	text-align: center;
	overflow: hidden;

	a {
		img {
			width: 100%;
			height: auto;
		}

		&:before {
			background: rgba(255, 255, 255, 0.2);
			bottom: 0;
			content: "";
			left: 50%;
			position: absolute;
			right: 51%;
			top: 0;
			opacity: 1;


		}

		&:after {
			background: rgba(255, 255, 255, 0.2);
			bottom: 50%;
			content: "";
			left: 0;
			position: absolute;
			right: 0;
			top: 50%;
			opacity: 1;

		}
	}

	&:hover a:before {
		left: 0;
		right: 0;
		opacity: 0;
		@include pos-transition(900ms);
	}

	&:hover a:after {
		top: 0;
		bottom: 0;
		opacity: 0;
		@include pos-transition(900ms);
	}
}

/* end module advertising */
/* module tags */
#tags_block {
	background: $light;
	padding: 15px 0;

	h2 {
		font-size: 14px;
		line-height: 14px;
		font-weight: 400;
		color: #3e444a;
		margin: 0;
		text-transform: capitalize;
		float: left;
		margin-right: 30px;
	}

	.tags_block {
		line-height: 1;

		@media (max-width:991px) {
			display: inline-block;
			width: 100%;
			margin-top: 15px;
		}

		li {
			line-height: 1;
			float: left;
			list-style: none;

			&:first-child {
				a {
					padding-left: 0;
					border-left: 0;
				}
			}

			a {
				display: block;
				float: left;
				padding: 0 15px;
				font-size: 14px;
				line-height: 14px;

				@media (max-width:767px) {
					padding: 0 10px;
					font-size: 12px;
				}

				color:#919191;
				font-weight:400;
				border-left:$main_border;
				text-transform:capitalize;

				&:hover {
					color: $main;
				}
			}
		}
	}
}

/* end module tags */

/* module testimonials */
.testimonials_container {
	position: relative;

	.pos_title {
		&:before {
			content: "";
			background: url(../img/icon_testimonial.png) no-repeat $main;
			width: 50px;
			height: 50px;
			display: inline-block;
			vertical-align: top;
		}
	}

	.item-testimonials {
		.item {
			position: relative;
			text-align: center;

			&:before {
				background: url(../img/bg_testimonial.jpg) no-repeat $light;
				content: "";
				height: 155px;
				background-size: cover;
				display: block;
			}

			.des_testimonial {
				position: relative;
				margin-bottom: 10px;

				@media (max-width:1199px) {
					margin-bottom: 30px;
				}

				&:before {
					content: "";
					display: inline-block;
					position: absolute;
					left: 20px;
					bottom: -10px;

				}

				p {
					color: #3e444a;
					line-height: 24px;
					margin: 0;
					font-size: 16px;
				}
			}

			img {
				margin-bottom: 7px;
				border: 2px solid $main;
				border-radius: 100%;
				width: 86px;
				height: 86px;
				border: 3px solid $main;
				margin-top: -43px;
			}

			.content_author {

				.des_namepost {
					font-size: 16px;
					color: $main;
					line-height: 30px;
					font-weight: 500;
					text-transform: capitalize;
					margin: 0;
				}

				.des_email {
					font-size: 14px;
					color: #959595;
					line-height: 20px;
					margin: 0;

					&:hover {
						color: $main;
					}
				}
			}
		}
	}

}

/* end module testimonials */

/* static */
@-webkit-keyframes fadeInRightBanner {
	from {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInRightBanner {
	from {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

.fadeInRightBanner {
	-webkit-animation-name: fadeInRightBanner;
	animation-name: fadeInRightBanner;
}


.home-banner {
	position: relative;
	display: inline-block;
	width: 100%;

	@media (min-width: 992px) {
		margin-bottom: 20px;
	}

	.row {
		margin: 0 -5px;

		.col {
			padding: 0 5px;
		}
	}
}

.banner-box {
	position: relative;
	overflow: hidden;
	margin-bottom: 30px;

	img {
		width: 100%;
	}

	&:hover {
		.txt_banner {

			h2,
			h3,
			a {
				animation: fadeInRightBanner 0.5s linear alternate;
				-webkit-animation: fadeInRightBanner 0.5s linear alternate;
				-moz-animation: fadeInRightBanner 0.5s linear alternate;
			}
		}
	}

	.txt_banner {
		position: absolute;
		left: 50px;
		top: 50%;
		max-width: 40%;

		@media (min-width: 768px) and (max-width: 991px) {
			left: 20px;
			max-width: 50%;
		}

		@media (max-width: 440px) {
			left: 20px;
			max-width: 50%;
		}

		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);

		h2 {
			color: #747c83;
			font-size: 22px;
			text-transform: capitalize;
			font-weight: 400;
			margin: 0;

			@media (max-width: 440px) {
				font-size: 16px;
			}
		}

		h3 {
			color: #3e444a;
			font-size: 30px;

			@media (max-width: 1199px) {
				font-size: 24px;
			}

			text-transform:capitalize;
			font-weight:700;
			margin-bottom:30px;

			@media (min-width: 768px) and (max-width: 991px) {
				margin-bottom: 10px;
			}

			@media (max-width: 440px) {
				margin-bottom: 10px;
				font-size: 20px;
			}
		}

		a {
			display: inline-block;
			line-height: 40px;
			padding: 0 20px;
			font-size: 13px;
			text-transform: uppercase;
			color: #3e444a;
			background: $main;
			border-radius: 5px;

			@media (max-width: 440px) {
				line-height: 30px;
			}

			&:hover {
				background: #3e444a;
				color: $light;
			}
		}
	}

}

.connect-area {
	position: relative;
	overflow: hidden;
	margin-bottom: 50px;

	.container {
		position: relative;
	}

	img {
		width: 100%;
	}

	&:hover {
		.connect-content {

			h2,
			h3,
			p,
			a {
				animation: fadeInRightBanner 0.5s linear alternate;
				-webkit-animation: fadeInRightBanner 0.5s linear alternate;
				-moz-animation: fadeInRightBanner 0.5s linear alternate;
			}
		}
	}

	.connect-content {
		@media (min-width: 768px) {
			position: absolute;
			left: 15px;
			top: 50%;
			right: 15px;
			overflow: hidden;
			-moz-transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}

		.info {
			padding: 0;
		}

		@media (max-width: 767px) {
			margin-top: 10px;
		}

		h2 {
			color: #3e444a;
			font-size: 48px;

			@media (max-width: 1199px) {
				font-size: 36px;
			}

			@media (max-width: 440px) {
				font-size: 24px;
			}

			text-transform:none;
			font-weight:300;
			margin:0;
			line-height:1;

		}

		h3 {
			color: #3e444a;
			font-size: 48px;
			line-height: 1;

			@media (max-width: 1199px) {
				font-size: 36px;
			}

			text-transform:none;
			font-weight:700;
			margin-bottom:20px;

			@media (max-width: 440px) {
				font-size: 24px;
			}
		}

		p {
			color: #3e444a;
			font-size: 18px;
			font-weight: 300;
			margin: 0 0 40px 0;

			@media (max-width: 1199px) {
				font-size: 14px;
				margin: 0 0 30px 0;
			}

		}

		a {
			display: inline-block;
			line-height: 40px;
			padding: 0 20px;
			font-size: 13px;
			text-transform: uppercase;
			color: #3e444a;
			background: $main;
			border-radius: 5px;

			@media (max-width: 440px) {
				line-height: 30px;
			}

			&:hover {
				background: #3e444a;
				color: $light;
			}
		}
	}

}

.static_cms {
	margin-bottom: 80px;

	@media (max-width: 575px) {
		margin: 20px 0 50px;
	}

	.col-cms {
		border-left: $main_border;

		@media (max-width: 575px) {
			border: none;
		}

		&:first-child {
			border: none;
		}
	}

	.box_cms {
		display: flex;
		width: 100%;

		&:hover {
			.txt_cms {
				h2 {
					color: $main;
					-moz-transform: translateY(-5px);
					-webkit-transform: translateY(-5px);
					-o-transform: translateY(-5px);
					-ms-transform: translateY(-5px);
					transform: translateY(-5px);
					@include pos-transition(300ms);
				}
			}
		}

		.txt_cms {
			overflow: hidden;
			padding: 5px 0;
			display: flex;
			margin: auto;

			div {
				margin: auto 0;
			}

			h2 {
				line-height: 20px;
				color: #3e444a;
				font-size: 14px;
				font-weight: 700;
				text-transform: capitalize;
				margin: 0;
				font-family: 'Open Sans', sans-serif;
				@include pos-transition(300ms);
			}

			p {
				line-height: 20px;
				color: #757575;
				font-size: 13px;
				margin: 0;
			}
		}
	}
}

.static_footer {
	.phone {
		margin: 15px 0 0;
		color: $main;
		font-size: 30px;
		font-weight: 700;
		display: block;
	}

	.txt_info {
		h2 {
			font-size: 30px;

			@media (max-width: 1199px) {
				font-size: 26px;
			}

			font-weight:400;
			line-height:1;
			margin-bottom:0;

			span {
				font-weight: 700;
			}
		}

		p {
			color: #757575;
			font-size: 13px;
		}
	}

	a {
		float: right;
		display: inline-block;
		padding: 0 30px;
		line-height: 48px;
		background: $main;
		border-radius: 25px;
		color: $light;
		text-transform: uppercase;

		@media (max-width: 640px) {
			float: left;
			clear: both;
			margin-top: 15px;
		}

		&:hover {
			background: #2b3137;
		}
	}

	@media (max-width: 991px) {
		text-align: center;
		flex-direction: column;

		.phone {
			display: block;
			margin: 20px 0 0;
		}
	}
}

.tag-static {
	margin-top: 40px;
	padding: 40px 0;
	border-top: 1px solid rgba(255, 255, 255, 0.05);

	li {
		a {
			line-height: 30px;
			text-transform: capitalize;
			color: #5b697b;
			position: relative;
			display: inline-block;
			vertical-align: top;
			font-size: 14px;

			&:first-child {
				padding: 0 15px;
				font-weight: 400;
				color: #fff;
				background: #37475a;
				border-radius: 5px;
				margin-right: 8px;

				&:after {
					display: none;
				}
			}

			&:hover {
				color: $light;
			}

			&:after {
				content: "/";
				display: inline-block;
				vertical-align: top;
				margin: 0 9px;
				color: #5b697b;
			}

			&:last-child:after {
				display: none;
			}
		}
	}
}

/* end static */
/* footer */
#footer {
	padding: 0;
	color: #666666;

	p {
		color: #666666;
	}

	.footer-container {
		margin: 0;
		padding: 0;
		overflow: visible;
		box-shadow: none;
		background: $light;

		.footer-top {
			padding: 45px 0;
			background: $lightgrey;

			@media (max-width: 650px) {
				.container {
					max-width: 320px;
				}

				h2 {
					margin-bottom: 15px;
				}
			}
		}

		.footer-middle {
			padding: 45px 0;

			img {
				max-width: 150px;
			}
		}

		.footer-bottom {
			.container {
				position: relative;
				padding-top: 15px;
				padding-bottom: 15px;

				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: 15px;
					right: 15px;
					border-top: $main_border;
				}
			}

			.copyright {
				line-height: 30px;
				color: #999999;
				font-size: 13px;
				line-height: 23px;

				@media (max-width: 767px) {
					margin-bottom: 10px;
				}

				a {
					color: #3e444a;

					&:hover {
						color: $main;
					}
				}
			}

			.payment {
				text-align: right;

				@media (max-width: 767px) {
					text-align: left;
				}

				img {
					display: inline-block;
					margin: 0;
					max-width: 360px;
				}
			}
		}

		.about_us {
			line-height: 20px;

			.footer-info {
				padding-top: 15px;
			}

			.logo_footer {
				img {
					margin: 0;
				}

				margin-bottom:25px;
			}

			.desc_footer {
				margin-bottom: 20px;
			}

			.add {
				margin-bottom: 20px;

				h2 {
					font-size: 16px;
					color: #3e444a;
					text-transform: capitalize;
					margin-bottom: 10px;
				}

				p {
					font-size: 14px;
					color: #666666;
					margin-bottom: 0;
				}
			}
		}

		.footer_block {
			margin-bottom: 0;

			@media (max-width: 991px) {
				margin-bottom: 15px;
			}

			@media (min-width: 768px) and (max-width: 991px) {
				&:nth-child(3) {
					clear: both;
				}
			}

			@media (max-width: 767px) {
				.title {
					padding: 0;
					border: none;
				}
			}

			h3 {
				font-size: 17px;
				line-height: 45px;
				font-weight: 700;
				color: #333333;
				margin: 0 0 15px 0;
				text-transform: capitalize;
				font-family: 'Open Sans', sans-serif;

				@media (max-width: 767px) {
					margin: 0;
					line-height: 30px;
				}
			}

			.navbar-toggler {
				position: absolute;
				right: 15px;
				top: 5px;
				width: auto;
				height: auto;
				color: #666666;
			}

			.footer_list {
				margin-bottom: 0;
				background: none;

				li {
					display: block;
					margin: 0;
					line-height: 20px;
					margin-bottom: 10px;
					border: 0;
					padding: 0;
					font-weight: 400;

					a {
						font-size: 14px;
						line-height: 20px;
						display: block;
						position: relative;
						color: #666666;
						@include pos-transition(300ms);

						&:before {
							content: "\f105";
							font-family: "Font Awesome 5 Free";
							font-size: 14px;
							display: inline-block;
							margin-right: 5px;
							font-weight: 700;
							display: none;
						}

						&:hover {
							color: $main;
							padding-left: 10px;

							&:before {
								color: $main;
							}

							@include pos-transition(300ms);
						}

					}
				}
			}


		}

	}
}

/* end footer */

/* category page */
@media (max-width: 767px) {

	#left-column,
	#content-wrapper,
	#right-column {
		width: 100%;
	}

	#left-column {
		.advertising {
			display: none;
		}
	}
}

.name_category {
	text-align: center;
	margin-bottom: 40px;
	display: inline-block;
	width: 100%;

	h2 {
		display: inline-block;
		position: relative;
		font-size: 30px;
		font-weight: 500;
		color: white;
		margin: 0;
		line-height: 1;
		text-transform: uppercase;
	}
}

#js-product-list-top {
	ul.display {
		float: left;
		margin-right: 50px;
		position: relative;
		z-index: 1;

		@media (min-width: 768px) and (max-width: 991px) {
			margin-right: 10px;
		}

		@media (max-width: 767px) {
			margin-bottom: 10px;
		}

		li {
			float: left;
			line-height: 26px;
			margin-right: 5px;
			cursor: pointer;
			color: #dbdbdb;
			background: transparent;
			@include pos-transition(300ms);

			&:hover,
			&.selected {
				color: $main;
				@include pos-transition(300ms);
			}

			i {
				font-size: 24px;
			}
		}
	}
}

.quickview .modal-content {
	background: $light;
}

@media (min-width:1920px) {
	.modal {
		top: 50%;
		bottom: auto;
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	.modal-dialog {
		margin: 0 auto;
	}
}

#js-product-list {
	.product_content {
		position: relative;
		background: $light;

		&.list {
			.js-product-miniature {
				padding: 20px;
				margin-bottom: 20px;

				.row {
					margin: 0;
				}

				&:before {
					display: none;
				}

				.box-top,
				.img_block .add-to-links {
					display: none;
				}

				.img_block {
					padding: 0;
					overflow: hidden;
					margin: 0;
				}

				.product_desc {
					padding: 20px;
					text-align: left;

					@media (max-width: 479px) {
						padding: 20px 0 0 0;
					}

					@media (min-width: 992px) {
						padding-right: 200px;
					}

					.box-inner {
						@media (min-width: 992px) {
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							border-left: $main_border;
							min-width: 180px;
							padding-top: 20px;
							padding-left: 20px;
						}

						text-align:center;

						div.cart {
							position: static;
							margin-bottom: 10px;

							button.ajax_add_to_cart_button,
							span.ajax_add_to_cart_button {
								display: block;
								background: $main;
								color: #3e444a;
								line-height: 40px;
								height: 40px;
								width: 100%;
								padding: 0 10px;
								cursor: pointer;
								font-size: 15px;
								border: none;
								border-radius: 5px;
								text-transform: capitalize;
								@include pos-transform(1);
								opacity: 1;

								&:before {
									display: none;
								}

								&:hover {
									color: $light;
									background: #3e444a;
									@include pos-transition(300ms);
								}
							}
						}

						.add-to-links {
							position: static;

							li {
								float: none;
								clear: none;
								display: inline-block;
								height: 40px;
								opacity: 1;
								@include pos-transform(1);
								@include pos-transition(300ms);
							}

						}

						.product-price-and-shipping {
							margin-bottom: 40px;

							@media (max-width: 991px) {
								margin-bottom: 20px;
							}

							.price {
								font-size: 24px;
							}

							.regular-price {
								font-size: 22px;
							}
						}
					}

					.product_name {
						min-height: auto;
						text-transform: capitalize;
						font-size: 18px;
						color: $secondary;
						margin-top: 0 !important;
						margin-bottom: 20px;

						&:hover {
							color: #3e444a;
						}
					}

					.hook-reviews {
						margin: 0;
					}

					.product-desc {
						display: block;

						p {
							font-size: 14px;
							color: #757575;
							line-height: 25px;
							padding: 0;
						}
					}

					.variant-links {
						margin-top: 30px;
					}



				}

				@media (max-width: 479px) {

					.img_block,
					.product_desc {
						width: 100%;
					}
				}
			}
		}

		&.grid {
			margin: 0 -10px;

			.item-product {
				padding: 0 10px;
			}

			.item-product {
				.js-product-miniature {
					margin-bottom: 20px;

					&:before {
						left: -15px;
						right: auto;

					}

					&:after {
						content: "";
						border-bottom: $main_border;
						bottom: -30px;
						left: -30px;
						right: 0;
						position: absolute;

					}
				}

				@media (min-width: 480px) and (max-width: 543px) {
					width: 50%;
				}
			}

			@media (min-width:992px) {
				.item-product:nth-child(3n+1) {
					clear: both;

					.js-product-miniature {
						&:before {
							display: none;

						}

						&:after {
							left: 0;

						}
					}
				}
			}

			@media (min-width:480px) and (max-width: 991px) {
				.item-product:nth-child(2n+1) {
					clear: both;

					.js-product-miniature {
						&:before {
							display: none;

						}

						&:after {
							left: 0;
						}
					}
				}
			}

			@media (max-width: 479px) {
				.item-product {
					clear: both;

					.js-product-miniature {
						&:before {
							display: none;

						}

						&:after {
							left: 0;
						}
					}
				}
			}
		}
	}
}

@media (min-width:480px) and (max-width:543px) {
	#js-product-list .product_content .item-product.grid {
		float: left;
		width: 50%;
	}
}

.breadcrumb_container {
	overflow: hidden;
	margin: 0;
	font-size: 14px;

	.breadcrumb-inner {
		background: transparent;
		margin-top: 30px;

		ol {
			padding-left: 0;
			margin-bottom: 0;

			li {
				display: inline;

				&:after {
					content: "\f3d3";
					font-family: "Ionicons";
					color: #666666;
					margin: 0.3125em;
					font-size: 14px;
					vertical-align: -1px;
				}

				&:last-child {

					a,
					span {
						color: #333333;
					}

					&:after {
						content: "";
					}
				}

				a {
					display: inline-block;
					position: relative;
					color: #666666;
					line-height: 25px;

					&:hover {
						color: $main;
					}
				}
			}
		}
	}

	.breadcrumb-inner[data-depth="1"] {
		display: none;
	}
}

.block-category {
	padding: 0;
	min-height: auto;
	margin: 0;

	h1.h1 {
		font-weight: 500;
		color: #333333;
		font-size: 24px;
		line-height: 24px;
		padding: 0 0 15px 0;
		margin: 0;
		border: none;
	}

	.category-cover {
		position: static;
		margin-bottom: 20px;

		img {
			width: auto;
			height: auto;
		}
	}

	&.card {
		box-shadow: none;
		border: 0;
	}

}

#products img,
.featured-products img,
.product-accessories img {
	margin: 0;
}

.block-categories,
#search_filters {
	box-shadow: none;
	background: #f6f6f6;
	padding: 35px 20px;
	margin-bottom: 30px;
}

.block-categories {
	a.h6 {
		font-size: 16px;
		line-height: 1;
		font-weight: 700;
		color: #3e444a;
		margin-bottom: 15px;
		position: relative;
		display: block;

	}

	.category-sub-menu {
		margin: 0;

		li {
			display: inline-block;
			width: 100%;

			a {
				color: #3e444a;

				&:hover {
					color: $main;
				}
			}
		}
	}

	.category-sub-menu li[data-depth="0"]>a {
		font-weight: 400;
		color: #3e444a;
		display: block;
		font-size: 14px;
		text-transform: capitalize;
		line-height: 35px;
		margin: 0;
		padding: 0;
		border: none;

		&:hover {
			font-weight: 600;
		}
	}

	.category-sub-menu li[data-depth="1"] {
		margin: 0;
	}

	.category-sub-menu li:last-child a {
		border-bottom: 0;
	}

	.category-sub-menu .category-sub-link {
		font-size: 14px;
		line-height: 35px;
		margin: 0;
		padding: 0 10px;
		display: block;

	}

	.collapse-icons {
		top: 5px;
		right: 0;
		height: auto;
		width: auto;

		i {
			font-size: 18px;

			&:hover {
				color: $main;
			}
		}
	}


}

.block-categories .category-sub-menu li:not([data-depth="0"]):not([data-depth="1"]):before {
	display: none;
}

#search_filters_wrapper #search_filters h4 {
	font-size: 16px;
	line-height: 1;
	font-weight: 700;
	color: #3e444a;
	margin-bottom: 25px;
	position: relative;
	display: block;
}

#search_filters .facet {
	.facet-title {
		display: block;
		clear: both;
		color: #3e444a;
		font-weight: 700;
		font-size: 16px;
		margin-bottom: 10px;
		line-height: 24px;
		text-transform: capitalize;
	}

	.collapse {
		margin: 0;

		li {
			line-height: 25px;
		}
	}

	.custom-checkbox input[type="checkbox"]+span {
		margin: -3px 3px 0 0;
		width: 15px;
		height: 15px;
		border: 1px #3e444a solid;
		border-radius: 2px;

		&.color {
			border: 0;
		}
	}

	.facet-label {
		margin: 5px 0;

		a {
			color: #3e444a;
			font-size: 14px;
			margin-top: 0;

			&:hover {
				color: $main;
			}
		}
	}
}

/* Drop-down list */
.facet-dropdown {
	color: #3e444a;
	background: $light;
	border: none;
	box-shadow: none;
}

.facet-dropdown .select-title {
	border: $main_border;
	color: #3e444a;
	padding: 10px 15px;
	border-radius: 5px;
	background: #ffffff;
	background: -moz-linear-gradient(top, white 1%, #f3f3f3 100%);
	background: -webkit-linear-gradient(top, white 1%, #f3f3f3 100%);
	background: linear-gradient(to bottom, #ffffff 1%, #f3f3f3 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f3f3f3', GradientType=0);
}

.facet-dropdown.open>.select-title {
	border: $main_border;
	background: #ffffff;
	background: -moz-linear-gradient(top, white 1%, #f3f3f3 100%);
	background: -webkit-linear-gradient(top, white 1%, #f3f3f3 100%);
	background: linear-gradient(to bottom, #ffffff 1%, #f3f3f3 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f3f3f3', GradientType=0);
}

.facet-dropdown .dropdown-menu {
	border: $main_border;
	background: -moz-linear-gradient(top, white 1%, #f3f3f3 100%);
	background: -webkit-linear-gradient(top, white 1%, #f3f3f3 100%);
	background: linear-gradient(to bottom, #ffffff 1%, #f3f3f3 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f3f3f3', GradientType=0);
	box-shadow: none;
}

.facet-dropdown .select-list {
	display: block;
	color: #3e444a;
	font-size: 14px;
	background: transparent;
}

/* end Drop-down list */
/* page list */
.pagination .page-list {
	box-shadow: none;
	padding: 0;
	text-align: left !important;
}

.pagination {
	font-size: 14px;
	font-weight: 400;
	border: $main_border;
	padding: 15px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	margin: 20px 0;
	line-height: 40px;
	color: #3e444a;

	.previous,
	.next {
		float: none;
	}

	a {
		font-weight: 400;
		color: #3e444a;
		padding: 0 10px;
		height: 28px;
		line-height: 28px;
		background: #f1f2f4;
		border-radius: 5px;
		font-size: 14px;
		display: inline-block;

		i {
			font-size: 14px;
		}

		&:hover {
			background: $main;
			color: $light;
		}
	}

	.disabled {
		color: #3e444a;
	}

	.current a {
		background: $main;
		font-size: 14px;
		color: $light;
	}

	.show_items {
		line-height: 28px;
		font-size: 14px;
	}

	>div:first-child {
		line-height: 1;
	}
}

/*end page list */
@media (max-width: 767px) {
	#category #left-column #search_filters .facet .navbar-toggler {
		padding: 0.625rem 3rem 0 0;
	}
}

.products-selection .filter-button .btn-secondary,
.products-selection .filter-button .btn-tertiary {
	box-shadow: none;
	padding: 4px 15px;
	border-radius: 4px;
}

.products-selection .total-products {
	padding-top: 0;
}

.products-selection {
	border: $main_border;
	padding: 15px;
	margin-bottom: 20px;
	display: inline-block;
	width: 100%;

	p,
	.sort-by {
		line-height: 20px;
		font-size: 14px;
		padding: 5px;
		margin: 0;
		float: left;
		color: #757575;
		font-weight: 400;
		text-transform: capitalize;
	}

	.products-sort-order .select-title {
		border: $main_border;
		padding: 2px 15px;
		line-height: 24px;
		font-size: 14px;
		margin: 0;
		color: #757575;
		text-align: left;
		box-shadow: none;

		i {
			float: right;
		}
	}

	.products-sort-order .dropdown-menu {
		background: #fff;
		border: 1px solid #e1e1e1;
		right: 15px;
		left: auto;
		text-align: right;
		box-shadow: none;
		max-width: 100%;

		.select-list {
			line-height: 24px;
			font-size: 14px;
			padding: 3px 15px;

			&:hover {
				background: $main;
			}
		}
	}
}


.text-muted {
	margin-bottom: 20px;
}

/* end category page */

/* product page */
.box_content {
	border: $main_border;
	padding: 20px;
	display: inline-block;
	width: 100%;
}

.h1.namne_details,
.product_name_h1 {
	font-size: 24px;
	line-height: 1;
	color: $secondary;
	text-transform: capitalize;
	font-weight: 400;
	margin: 0 0 20px 0;

}

.product-prices {
	margin: 0;

	.price,
	.current-price span:first-child {
		font-weight: 600;
		font-size: 32px;
		line-height: 30px;
		color: #3e444a;
	}

	.sale_details {
		color: #fd5018 !important;
	}
}

.product-discount .regular-price {
	font-weight: 400;
	font-size: 16px;
	line-height: 40px;
	color: #a4a4a4;
}

.has-discount.product-price,
.has-discount p {
	font-weight: 600;
	font-size: 32px;
	line-height: 30px;
	display: inline-block;
	color: #3e444a;
}

.has-discount .discount {
	font-weight: 600;
	font-size: 14px;
	line-height: 30px;
	color: #fff;
	padding: 0 15px;
	background: $main;
	vertical-align: 7px;
	margin-left: 0;
	border-radius: 5px;
}

.product-information {
	color: #3e444a;
	font-size: 14px;
	font-weight: normal;
	line-height: 24px;

	span {
		font-size: 14px;
	}

	.product-desc {
		margin: 30px 0 0 0;
		padding: 30px 0;
		border-top: $main_border;
		font-size: 14px;
		color: #757575;

		p {
			font-size: 14px;
			color: #757575;
			margin: 0;

			span {
				font-size: 14px !important;
				font-family: 'Open Sans', sans-serif !important;
				color: #757575;
			}

		}
	}

}

.product-prices div {
	margin: 0;
}

.bootstrap-touchspin,
.product-variants>.product-variants-item select {
	box-shadow: none;
}

.product-variants>.product-variants-item {
	margin: 0 0 20px 0;
}

@media (max-width: 639px) {
	.product-variants {
		select {
			width: 100% !important;
			overflow: hidden !important;
		}
	}
}

.product-quantity {
	display: block;

	.qty {
		margin-right: 10px;
	}

	.add {
		@media (max-width: 360px) {
			clear: both;
			margin-top: 10px;
		}
	}
}

.product-quantity #quantity_wanted {
	height: 50px;
}

.product-quantity .btn-touchspin {
	height: 26px;

	&:hover {
		border: 1px solid rgba(0, 0, 0, .25);
	}
}

.product-additional-info {
	margin-top: 20px;
}

.product-actions .product-add-to-cart {
	padding-top: 25px;
	border-top: $main_border;
}

.product-actions .add-to-cart {
	position: relative;
	padding: 0 60px;
	color: #fff;
	line-height: 50px;
	height: 50px;
	font-size: 16px;
	font-weight: 700;
	border: none;
	background: $main;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	border-radius: 100px;
	box-shadow: none;
	text-transform: capitalize;

	&:hover {
		background: #3e444a;
		color: $light;
	}

	&:before {
		display: inline-block;
		margin-right: 5px;
		content: '+';
	}

	i {
		display: none;
	}
}

.reference {
	font-size: 14px;
	color: #3e444a;
	margin: 0;
}

#block-reassurance {
	box-shadow: none !important;
	margin-top: 20px;
	display: inline-block;
	width: 100%;
	border-top: $main_border;
	padding: 20px 0 0 0;

	span {
		font-weight: 400 !important;
		font-size: 14px;
	}

	li {
		border: none !important;

		.block-reassurance-item {
			padding: 10px;
			float: left;
			width: 33.33%;
			border: none !important;

			img {
				float: left;
				margin-right: 20px;
				width: auto;
			}

			span.h6 {
				overflow: hidden;
				color: #757575;
				display: block;
			}
		}

		@media (max-width: 543px) {
			width: 100%;
			display: inline-block;
			margin-bottom: 20px;

			&:last-child {
				margin: 0;
			}

			.block-reassurance-item {
				width: 100%;
			}
		}
	}
}

#product #content {
	overflow: hidden;
	max-width: 470px;
}

#product #content {
	.product-flag .new {
		top: 10px !important;
		bottom: auto;
		z-index: 1;
		left: 10px;
	}
}

.product-cover img {
	box-shadow: none;
	border: none;
}

li.product-flag {
	background: $main;
	color: white;
	font-size: 12px;
	padding: 0;
	min-width: 60px;
	text-align: center;
	display: block;
	z-index: 1;
	line-height: 30px;
	border-radius: 20px;
	font-weight: 500;
	height: 30px;
	margin: 0;
}

.social-sharing {
	margin: 0;
	line-height: 1;
}

.social-sharing li {
	box-shadow: none;
	height: auto;
	width: auto;
	border-radius: 0;
	display: inline-block;
	background: none;
	line-height: 1;
	@include pos-transition(300ms);

	&:hover {
		a {
			color: $main;
		}
	}

	a {
		display: inline-block;
		line-height: 24px;
		height: 24px;
		text-align: center;
		color: #b4b4b4;
		margin-right: 5px;
		white-space: normal;
		text-indent: 0;
		overflow: hidden;
		font-size: 0;
		padding: 0;

		&:before {
			font-family: "Ionicons";
			font-size: 18px;
			display: block;
			background: transparent;
			margin-right: 5px;
		}

	}

	&.facebook a {
		&:before {
			content: "\f231";
		}
	}

	&.twitter a {
		&:before {
			content: "\f243";
		}
	}

	&.googleplus a {
		&:before {
			content: "\f234";
		}
	}

	&.pinterest a {
		&:before {
			content: "\f2b1";
		}
	}
}

.tabs {
	box-shadow: none;
	display: inline-block;
	width: 100%;
	margin: 100px 0;
	padding: 30px;
	position: relative;
	background: $light;

	&:before {
		content: "";
		background: #f6f6f6;
		position: absolute;
		left: -9999rem;
		right: -9999rem;
		top: -50px;
		bottom: -50px;
		z-index: -1;
	}

	.nav-tabs {
		border: none;
		position: relative;
		display: block;
		border-bottom: $main_border;
		text-align: center;

		.nav-item {
			position: relative;
			display: inline-block;
			margin: 0;
			float: none;

			@media (max-width: 639px) {
				width: 100%;

				.nav-link {
					border: none !important;
					padding: 10px 0 !important;
					margin: 0 !important;
				}
			}

			&:first-child {
				.nav-link {
					margin-left: 0;
				}
			}

			.nav-link {
				background: transparent;
				border: 0;
				text-transform: capitalize;
				line-height: 25px;
				font-weight: 600;
				color: #a9a9a9;
				padding: 15px 0;
				margin: 0 30px;
				font-size: 18px;
				position: relative;
				@include pos-transition(300ms);

				&:before {
					content: "";
					position: absolute;
					bottom: -2px;
					left: 50%;
					right: 50%;
					height: 2px;
					background: transparent;
					@include pos-transition(300ms);
				}

				span {
					position: relative;
					z-index: 1;
				}

				&:hover,
				&.active {
					color: #3e444a;

					&:before {
						left: 0;
						right: 0;
						background: $main;
						@include pos-transition(300ms);
					}

					@include pos-transition(300ms);

				}

			}
		}
	}
}

.tab-content {
	overflow: hidden;
	padding: 30px 0;
	background: $light;
	font-size: 14px;
	background: $light;
	line-height: 24px;
}

@media (max-width: 639px) {
	#new_comment_tab_btn {
		white-space: unset;
		font-size: 11px;
		width: 100%;
		text-align: center;
	}
}

.product-description {
	font-size: 14px;
	color: #757575;

	p {
		font-size: 14px;
		color: #757575;
		margin-bottom: 20px;

		span {
			font-size: 14px !important;
			font-family: 'Open Sans', sans-serif !important;
		}
	}

	ul {
		list-style: disc;
		padding: 0 0 20px 20px;

		p {
			margin: 0;
		}
	}
}

#tab-content {
	font-size: 12px;

	label {
		font-size: 12px;
	}
}

#product-modal .modal-content .modal-body .product-images img {
	max-width: none;
	border: transparent 3px solid;
	@include pos-transition(300ms);

	&:hover {
		@include pos-transition(300ms);
	}
}

.images-container {
	max-width: 510px;
	margin: auto;
	position: relative;
	margin-bottom: 30px;

	img {
		display: none;
	}

	.slick-slider img {
		display: block;
	}
}

.product-images {
	@media (min-width: 1200px) {
		max-width: 85%;
	}

	max-width: 80%;
	margin:auto;

	.slick-prev,
	.slick-next {
		&:before {
			color: #e2e2e2;
			opacity: 1;
		}

		&:hover {
			&:before {
				color: $main;
			}
		}
	}

	.thumb-container {
		position: relative;
		cursor: pointer;

		>div {
			margin: 0 5px;
			position: relative;

			&:before {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				border: 1px solid transparent;
				pointer-events: none;
			}
		}

		&.slick-current>div {
			&:before {
				border-color: $main;
			}
		}

		img {
			width: 100%;
		}
	}

}

.product-cover {
	cursor: pointer;

	img {
		box-shadow: none;

	}

	@include pos-transition(300ms);

	&:before {
		font-family: "Ionicons";
		content: "\f25e";
		position: absolute;
		bottom: 20px;
		right: 20px;
		color: #e2e2e2;
		z-index: 1;
		width: 50px;
		height: 50px;
		background: rgba(255, 255, 255, 0.7);
		line-height: 50px;
		font-size: 50px;
		text-align: center;
		cursor: pointer;
		border-radius: 3px;
		pointer-events: none;
		opacity: 0;

		@media (max-width:991px) {
			opacity: 1;
		}
	}

	&:hover {
		&:before {
			opacity: 1;
			background: rgba(255, 255, 255, 1);
			@include pos-transition(300ms);
		}
	}
}

.quickview .product-cover:before {
	display: none;
}

.modal-content {
	.thumb-container {
		margin-bottom: 10px;
	}
}

.product-cover .layer {
	background: transparent;
	@include pos-transition(300ms);
}

.product-cover .layer .zoom-in {
	color: $main;
	font-size: 4.25rem;
}

.product-accessories,
.categoryproducts {
	margin-bottom: 50px;
}

.scroll-box-arrows.scroll {
	display: none;
}

.img-thumbnail {
	margin-bottom: 20px;
}

#product-modal .modal-content .modal-body .product-images img {
	max-width: 90px;
	margin: 0;
}

#product-modal .modal-content .modal-body .image-caption {
	width: 100%;
}

#product-modal .modal-content .modal-body .mask {
	max-height: 390px;
}

#product-modal .modal-content .modal-body {
	margin: 0;
	left: 50%;
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

@media (min-width:992px) {
	#product-modal .modal-content .modal-body .product-images {
		display: inline-block;
	}

	.product-images>li.thumb-container {
		float: left;
		clear: both;
		display: inline-block;
	}
}

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 700px;
	}
}

@media (max-width: 639px) {
	#blockcart-modal .cart-content {
		.cart-content-btn {
			display: flex;
			flex-direction: column;

			button {
				margin-right: 0;
				margin-top: 20px;
			}
		}

		.btn {
			margin-top: 5px;
		}
	}
}

.quickview .social-sharing {
	margin: 0;
	text-align: left;
}

.product-discounts {
	margin: 0;
}

.quickview #product_comments_block_extra .comments_advices {
	display: none;
}

#blockcart-modal .modal-title,
#blockcart-modal button.close {
	color: $light;
	font-weight: 400;
}

.product-line-grid-body>.product-line-info>.label {
	color: $secondary;
	font-size: 14px;
	margin-bottom: 5px;

	&:hover {
		color: $main;
	}
}

.product-line-info {
	text-align: left;
}

.product-line-grid-body .product-discount .regular-price {
	font-size: 16px;
	color: #757575;
}

.product-line-grid-body .current-price {
	line-height: 1;
}

.product-line-grid-body .current-price .price {
	font-size: 16px;
	color: #fd5018;
	font-weight: 600;
}

.product-line-grid-body .has-discount .discount {
	line-height: 22px;
	vertical-align: bottom;
	border-radius: 5px;
}

.modal-content {
	border: none;
}

.cart-grid-body {
	a.label {
		font-size: 17px;
	}

	.qty {
		padding-right: 0;
	}
}

.panel-product-actions {
	margin-top: 15px;
	text-align: left;
}

.panel-product-line {
	display: inline-block;
	vertical-align: top;
	float: left;

	#wishlist_button,
	.compare-button {
		line-height: 30px;
		padding: 0;
		border: none;
		color: #3e444a;
		font-size: 14px;
		background: transparent;
		margin-right: 15px;
		cursor: pointer;

		&:hover {
			color: $main;
		}

		i {
			display: inline-block;
			margin-right: 5px;
			font-size: 16px;
			font-weight: 600;
			vertical-align: -1px;
		}
	}
}

@media (max-width: 1199px) {
	.zoomContainer {
		display: none !important;
	}

	.zoomWrapper {
		width: auto !important;
		height: auto !important;
	}
}

/* end product page */

/* ===== end edit theme ======== */
/* end custom */

#product_comments_block_tab a {
	color: #fff;
}

.product-flag .on-sale {
	background: none;

	span {
		padding: 0 5px;
	}
}

.about-us-block {
	text-align: center;
	margin-bottom: 30px;
	max-width: 870px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 10px;

	h2 {
		margin: 0;
		position: relative;
		z-index: 1;
		background: $light;
		color: #3e444a;
		font-size: 26px;
		font-weight: 400;
		line-height: 35px;
		display: inline-block;
		text-transform: capitalize;

		span {
			color: $main;
		}

		@media (max-width: 1199px) {
			font-size: 18px;
		}
	}

	h3 {
		margin-bottom: 20px;
		color: #3e444a;
	}

	p {
		line-height: 20px;
		margin-bottom: 20px;
	}
}

.blockreassurance_product {
	text-transform: uppercase;
	margin-top: 65px;

	&:before {
		content: "";
		display: table;
		clear: both;
	}

	div {
		display: grid;
		grid-column-gap: 10px;
		grid-template-columns: auto 1fr;
		margin: 5px 0;
		height: 45px;
	}

	img {
		width: 35px;
		margin: 10px 0;
	}

	p {
		font-weight: 600;
		margin: auto 0;
		line-height: 1.25;

		&.block-title {
			line-height: 1;
		}
	}
}

#google_translate_element {
	position: absolute;
	top: 9px;

	@include screenM {
		display: none;
	}
}

.logo-slider {
	.owl-stage {
		margin: 0 auto;
	}
}

.data.email {
	a {
		color: $main;

		&:hover {
			text-decoration: underline;
		}
	}
}